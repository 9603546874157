import styled from 'styled-components'
import { shadow } from 'styles/theme'

// Styled components

export const Container = styled.div`
  display: block;
  padding: 0;
  border: 0 none;
  border-radius: 16px;
  text-decoration: none;
  background: white;
  box-shadow: ${shadow.card};
`
