import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Text from 'camino-web/components/Text'
import { SelectField } from 'components/Form'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { getAllDashboards, getUserPublicDashboards, getUserPrivateDashboards, updateDashboard, deleteDashboard } from 'store/actions/dashboard'
import { getAllSectors } from 'store/actions/sector'
import { getAllUsers } from 'store/actions/user'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useToast } from 'camino-web/components/Toast'
// import _ from 'lodash'
import * as S from './styles'
import './tabs.css'

const EditDashboard = () => {
  const dispatch = useDispatch()
  const { addToast } = useToast()
  const [editDashboard, setEditDashboards] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const publicDashboards = useSelector(state => state.dashboard.user_public_dashboards)
  const privateDashboards = useSelector(state => state.dashboard.user_private_dashboards)
  const { dashboards } = useSelector(state => state.dashboard)
  const { sectors } = useSelector(state => state.sector)
  const { users } = useSelector(state => state.user)

  useEffect(() => {
    dispatch(getUserPublicDashboards())
    dispatch(getUserPrivateDashboards())
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllDashboards())
    dispatch(getAllSectors())
    dispatch(getAllUsers())
  }, [dispatch])

  const openModal = (dashboard, modal) => {
    setEditDashboards(dashboard)

    if (modal === 'edit') {
      setShowModalEdit(prev => !prev)
    } else if (modal === 'delete') {
      setShowModalDelete(prev => !prev)
    }
  }

  const updateDashboardRequest = async (values) => {
    setShowModalEdit(false)
    try {
      const response = await dispatch(updateDashboard(values))
      if (response !== 200) {
        addToast('Erro - Falha ao editar Dashboard. Tente novamente!', { type: 'alert' })
      } else {
        dispatch(getUserPublicDashboards())
        dispatch(getUserPrivateDashboards())
        dispatch(getAllDashboards())
        addToast('Dashboard alterado com Sucesso!', { type: 'success' })
      }
    } catch (error) {
      addToast(error.message, { type: 'alert' })
    }
  }

  const deleteDashboardRequest = async (value) => {
    setShowModalDelete(false)
    try {
      const response = await dispatch(deleteDashboard(value))
      if (response !== 200) {
        addToast('Erro - Falha ao excluir Dashboard. Tente novamente!', { type: 'alert' })
      } else {
        addToast('Dashboard excluído com Sucesso!', { type: 'success' })
      }
    } catch (error) {
      addToast(error.message, { type: 'alert' })
    }
  }

  function validateName(value) {
    let error

    if (!value) {
      error = 'Insira um nome.'
    } else if (dashboards.filter(item => item.id !== editDashboard.id).some(item => item.name === value)) {
      error = 'Esse nome já existe!'
    }

    return error
  }

  function validateReportID(value) {
    let error
    const re = new RegExp('^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$')

    if (!value) {
      error = 'Insira um ID de Report.'
    } else if (!re.test(value)) {
      error = 'ID de Report inválido!'
    } else if (dashboards.filter(item => item.id !== editDashboard.id).some(item => item.report_id === value)) {
      error = 'Esse ID de Report já existe!'
    }

    return error
  }

  function validateWorkspaceID(value) {
    let error
    const re = new RegExp('^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$')

    if (!value) {
      error = 'Insira um ID de Workspace.'
    } else if (!re.test(value)) {
      error = 'ID de Workspace inválido!'
    }

    return error
  }

  function validateSector(value) {
    let error

    if (!value) {
      error = 'Selecione um sector.'
    }

    return error
  }

  function validateOwner(value) {
    let error

    if (!value) {
      error = 'Selecione um responsável.'
    }

    return error
  }

  function validatePrivateUsers(list) {
    let error

    if (!list.length) {
      error = 'Selecione pelo menos um usuário.'
    }

    return error
  }

  function renderContent() {
    if (!privateDashboards) {
      return null
    }
    return (
      <>
        <Text type="H2">Edição de Dashboards</Text>
        <Tabs>
          <TabList>
            <Tab>Público</Tab>
            <Tab>Privado</Tab>
          </TabList>

          <TabPanel>
            {publicDashboards?.map(dashesList => (
              <>
                {dashesList.dashboards.length > 0 && (
                  <>
                    <S.SText type="H3" key={dashesList.sector}>
                      ∙
                      {' '}
                      {dashesList.sector}
                    </S.SText>
                    <S.Table>
                      <tbody>
                        <S.TR>
                          <S.TH>Nome</S.TH>
                          <S.TH>Report ID</S.TH>
                          <S.TH>Workspace ID</S.TH>
                          <S.TH>Responsável</S.TH>
                          <S.TH>#</S.TH>
                        </S.TR>
                        {dashesList.dashboards.map((dashboard) => (
                          <S.TR key={dashboard.id}>
                            <S.TD>{dashboard.name}</S.TD>
                            <S.TD>{dashboard.report_id}</S.TD>
                            <S.TD>{dashboard.workspace_id}</S.TD>
                            <S.TD>{dashboard.owner}</S.TD>
                            <S.TD>
                              <S.SButton onClick={() => openModal(dashboard, 'edit')}>
                                <S.SIcon name="create" />
                              </S.SButton>
                              <S.SButton onClick={() => openModal(dashboard, 'delete')}>
                                <S.SIcon name="delete" />
                              </S.SButton>
                            </S.TD>
                          </S.TR>
                        ))}
                      </tbody>
                    </S.Table>
                  </>
                )}
              </>
            ))}
          </TabPanel>
          <TabPanel>
            {privateDashboards?.map(dashesList => (
              <>
                {dashesList.dashboards.length > 0 && (
                  <>
                    <S.SText type="H3" key={dashesList.sector}>
                      ∙
                      {' '}
                      {dashesList.sector}
                    </S.SText>
                    <S.Table>
                      <tbody>
                        <S.TR>
                          <S.TH>Nome</S.TH>
                          <S.TH>Report ID</S.TH>
                          <S.TH>Workspace ID</S.TH>
                          <S.TH>Responsável</S.TH>
                          <S.TH>#</S.TH>
                        </S.TR>
                        {dashesList.dashboards.map((dashboard) => (
                          <S.TR key={dashboard.id}>
                            <S.TD>{dashboard.name}</S.TD>
                            <S.TD>{dashboard.report_id}</S.TD>
                            <S.TD>{dashboard.workspace_id}</S.TD>
                            <S.TD>{dashboard.owner}</S.TD>
                            <S.TD>
                              <S.SButton onClick={() => openModal(dashboard, 'edit')}>
                                <S.SIcon name="create" />
                              </S.SButton>
                              <S.SButton onClick={() => openModal(dashboard, 'delete')}>
                                <S.SIcon name="delete" />
                              </S.SButton>
                            </S.TD>
                          </S.TR>
                        ))}
                      </tbody>
                    </S.Table>
                  </>
                )}
              </>
            ))}
          </TabPanel>
        </Tabs>
      </>
    )
  }

  const onChange = (e) => {
    setEditDashboards({ ...editDashboard, is_private: e.target.value === 'private' })
  }

  function renderPrivacy(isPrivate) {
    let element
    if (isPrivate) {
      element = (
        <>
          <S.Label htmlFor="users">Usuários permitidos</S.Label>
          <ErrorMessage name="users" component={S.FieldErrorMessage} />
          <S.DivSelect>
            <Field
              name="users"
              placeholder="Selecione..."
              maxLength={72}
              component={SelectField}
              options={users.map(({ email, name }) => ({ label: name, value: email }))}
              value={editDashboard.users}
              validate={validatePrivateUsers}
              isMulti
              required
            />
          </S.DivSelect>
        </>
      )
    }

    return (
      element
    )
  }

  return (
    <S.Container>
      {renderContent()}

      <S.Modal open={showModalEdit}>
        <S.ModalContent>
          <S.ModalHeader>
            <Text type="H2">Edição de Dashboard </Text>
            <S.Close type="button" onClick={() => setShowModalEdit(false)}>&times;</S.Close>
          </S.ModalHeader>
          <S.ModalBody>
            <Formik
              enableReinitialize
              initialValues={{
                id: editDashboard.id,
                dashboardName: editDashboard.name,
                reportId: editDashboard.report_id,
                workspaceId: editDashboard.workspace_id,
                platformId: editDashboard.platform,
                isPrivate: editDashboard.is_private,
                sectorId: editDashboard.sector_id,
                ownerId: editDashboard.owner,
                users: editDashboard.users
              }}
              onSubmit={updateDashboardRequest}
            >
              <Form>
                <S.Label htmlFor="dashName">Nome do Dashboard</S.Label>
                <ErrorMessage name="dashboardName" component={S.FieldErrorMessage} />
                <Field
                  name="dashboardName"
                  as={S.Input}
                  validate={validateName}
                  autoComplete="off"
                  required
                />

                <S.Label htmlFor="idReport">Id do Relatório Power BI</S.Label>
                <ErrorMessage name="reportId" component={S.FieldErrorMessage} />
                <Field
                  name="reportId"
                  as={S.Input}
                  validate={validateReportID}
                  autoComplete="off"
                  required
                />

                <S.Label htmlFor="workspaceId">Id Workspace Power BI</S.Label>
                <ErrorMessage name="workspaceId" component={S.FieldErrorMessage} />
                <Field
                  name="workspaceId"
                  as={S.Input}
                  validate={validateWorkspaceID}
                  autoComplete="off"
                  required
                />

                <S.DivSelect>
                  <S.Label htmlFor="sectorId">Setor</S.Label>
                  <ErrorMessage name="sectorId" component={S.FieldErrorMessage} />
                  <Field
                    name="sectorId"
                    placeholder="Selecione..."
                    maxLength={72}
                    component={SelectField}
                    options={sectors.map(({ id, name }) => ({ label: name, value: id }))}
                    value={editDashboard.sectorId}
                    validate={validateSector}
                  />
                </S.DivSelect>

                <S.DivSelect>
                  <S.Label htmlFor="ownerId">Responsável</S.Label>
                  <ErrorMessage name="ownerId" component={S.FieldErrorMessage} />
                  <Field
                    name="ownerId"
                    placeholder="Selecione..."
                    maxLength={72}
                    component={SelectField}
                    options={users.map(({ email, name }) => ({ label: name, value: email }))}
                    value={editDashboard.ownerId}
                    validate={validateOwner}
                  />
                </S.DivSelect>

                <S.Label>
                  Tipo do Dashboard
                </S.Label>

                <S.RadioGroup htmlFor="isPrivate">
                  <S.RadioItem>
                    <Field
                      type="radio"
                      id="isPrivate"
                      name="isPrivate"
                      value="public"
                      checked={!editDashboard.is_private}
                      onChange={onChange}
                    />
                    Público
                  </S.RadioItem>

                  <S.RadioItem>
                    <Field
                      type="radio"
                      id="isPrivate"
                      name="isPrivate"
                      value="private"
                      checked={editDashboard.is_private}
                      onChange={onChange}
                    />
                    Privado
                  </S.RadioItem>
                </S.RadioGroup>

                {renderPrivacy(editDashboard.is_private)}

                <S.ModalFooter>
                  <S.SecondaryActionButton text="Cancelar" onClick={() => setShowModalEdit(false)} />
                  <S.PrimaryActionButton text="Salvar" type="submit" />
                </S.ModalFooter>
              </Form>
            </Formik>
          </S.ModalBody>
        </S.ModalContent>
      </S.Modal>

      <S.Modal open={showModalDelete}>
        <S.ModalContent>
          <S.ModalHeader>
            <Text type="H2">Exclusão de Dashboard </Text>
            <S.Close type="button" onClick={() => setShowModalDelete(false)}>&times;</S.Close>
          </S.ModalHeader>
          <S.ModalBody>
            <Formik
              enableReinitialize
              initialValues={{
                id: editDashboard.id,
                dashboardName: editDashboard.name
              }}
              onSubmit={() => { }}
            >
              <Form>
                <S.Label id="dashName" name="dashName">
                  Deseja realmente excluir permanentemente o Dashboard
                  {' '}
                  <b>{editDashboard.name}</b>
                  ?
                </S.Label>
              </Form>
            </Formik>
          </S.ModalBody>
          <S.ModalFooter>
            <S.SecondaryActionButton text="Cancelar" onClick={() => setShowModalDelete(false)} />
            <S.PrimaryActionButton text="Excluir" onClick={() => deleteDashboardRequest(editDashboard.id)} />
          </S.ModalFooter>
        </S.ModalContent>
      </S.Modal>
    </S.Container>
  )
}

export default EditDashboard
