// NPM imports

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Project imports
import avatarFromName from 'util/avatarFromName'
import { fonts } from 'styles/theme'

// Styled components

const Container = styled.div`
  display: block;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  ${props => (props.withBorder ? 'border: 1px solid white;' : '')}
  border-radius: 50%;
  background: white center;
  background-size: cover;
`

const NamePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.backgroundColor};
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  font-family: ${fonts.titleFamily};
  font-size: ${props => `${props.size / 2}px`};
  font-weight: normal;
  color: white;
  text-align: center;
  ${props => (props.withBorder ? 'border: 1px solid white;' : '')}
  border-radius: 50%;
`

// Component

function ProfilePicture({
  className,
  size,
  imgUrl,
  fullName,
  withBorder
}) {
  if (imgUrl) {
    return (
      <Container
        title={fullName}
        className={className}
        style={{ backgroundImage: `url(${imgUrl})` }}
        size={size}
        withBorder={withBorder}
      />
    )
  }

  if (fullName) {
    const obj = avatarFromName(fullName)
    return (
      <NamePlaceholder
        title={fullName}
        className={className}
        size={size}
        backgroundColor={obj.bgColor}
        withBorder={withBorder}
      >
        {obj.initials}
      </NamePlaceholder>
    )
  }
  return null
}

ProfilePicture.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  imgUrl: PropTypes.string,
  fullName: PropTypes.string,
  withBorder: PropTypes.bool
}

ProfilePicture.defaultProps = {
  className: '',
  size: 24,
  imgUrl: '',
  fullName: '',
  withBorder: false
}

export default ProfilePicture
