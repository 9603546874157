// NPM imports
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Project imports
import Icon from 'camino-web/components/Icon'
import { colors, fonts } from 'styles/theme'

// Styled components

const Container = styled.div`
  padding: 4px;
  border-radius: 4px;
  margin-top: 4px;
  font-family: ${fonts.textFamily};
  font-size: 12px;
  line-height: 16px;
  color: ${colors.alert};
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SIcon = styled(Icon)`
margin-right:6px;
`

// Component

export function FieldError({ className, iconName, iconSize, children }) {
  if (!children) {
    return null
  }

  return (
    <Container className={className}>
      {iconName && <SIcon name={iconName} size={iconSize} />}
      {children}
    </Container>
  )
}

FieldError.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  iconName: PropTypes.string,
  iconSize: PropTypes.string
}

FieldError.defaultProps = {
  className: '',
  children: '',
  iconName: 'error',
  iconSize: '16px'
}

export default FieldError
