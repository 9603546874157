import React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'

import GSvg from 'img/google.svg'
import { GIcon, LoginOption } from './styles'

export default function GoogleBtn() {
  const { toFederatedSignIn } = useAuthenticator()

  function authenticate() {
    toFederatedSignIn({ provider: 'Google' })
  }

  return (
    <>
      <LoginOption
        onClick={authenticate}
        marginRight
      >
        <GIcon id="login-google" src={GSvg} />
      </LoginOption>
    </>
  )
}
