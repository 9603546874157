import styled from 'styled-components'
import background from 'img/pattern-login-lg.svg'

export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background: url(${background}) center center / cover;
  padding: 100px 200px 100px 100px;
`

export const ContentLogin = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 370px;
  height: 460px;
  background-color: #ffffff;
  align-items: center;
  border: 1px solid #fafafa;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 16px;
`

export const CloeLogoContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  width: 130px;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`

export const CloeLogo = styled.img`
  height: auto;
`
