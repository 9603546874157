// Project imports
import { actions } from '../actions/dashboard'

const initialState = {
  name: '',
  report_id: '',
  workspace_id: '',
  platform: '',
  is_private: false,
  sector_id: null,
  owner: null,
  users: [],
  dashboards: [],
  user_public_dashboards: [],
  user_private_dashboards: [],
  sector_public_dashboards: [],
  sector_private_dashboards: [],
  error: ''
}

function reducer(state = initialState, action) {
  switch (action.type) {
    // ADD DASHBOARD REPORT REQUEST
    case actions.ADD_DASHBOARD_REQUEST:
      return {
        ...state,
        initialState
      }

    // ADD DASHBOARD REPORT SUCCESS
    case actions.ADD_DASHBOARD_SUCCESS:
      return {
        ...state,
        name: action.name,
        report_id: action.report_id,
        workspace_id: action.workspace_id,
        platform: action.platform,
        is_private: action.is_private,
        sector_id: action.sector_id,
        owner: action.owner,
        users: action.users
      }

    // ADD DASHBOARD REPORT FAILURE
    case actions.ADD_DASHBOARD_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // GET_ALL_DASHBOARDS REQUEST
    case actions.GET_ALL_DASHBOARDS_REQUEST:
      return {
        ...state,
        initialState
      }

    // GET_ALL_DASHBOARDS SUCCESS
    case actions.GET_ALL_DASHBOARDS_SUCCESS:
      return {
        ...state,
        dashboards: action.response
      }

    // GET_ALL_DASHBOARDS FAILURE
    case actions.GET_ALL_DASHBOARDS_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // GET_SECTOR_PUBLIC_DASHBOARDS_REQUEST
    case actions.GET_SECTOR_PUBLIC_DASHBOARDS_REQUEST:
      return {
        ...state,
        initialState,
        sector_public_dashboards: []
      }

    // GET_SECTOR_PUBLIC_DASHBOARDS_SUCCESS
    case actions.GET_SECTOR_PUBLIC_DASHBOARDS_SUCCESS:
      return {
        ...state,
        sector_public_dashboards: action.response
      }

    // GET_SECTOR_PUBLIC_DASHBOARDS_FAILURE
    case actions.GET_SECTOR_PUBLIC_DASHBOARDS_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // GET_SECTOR_PRIVATE_DASHBOARDS_REQUEST
    case actions.GET_SECTOR_PRIVATE_DASHBOARDS_REQUEST:
      return {
        ...state,
        initialState,
        sector_private_dashboards: []
      }

    // GET_SECTOR_PRIVATE_DASHBOARDS_SUCCESS
    case actions.GET_SECTOR_PRIVATE_DASHBOARDS_SUCCESS:
      return {
        ...state,
        sector_private_dashboards: action.response
      }

    // GET_SECTOR_PRIVATE_DASHBOARDS_FAILURE
    case actions.GET_SECTOR_PRIVATE_DASHBOARDS_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // GET_USER_PUBLIC_DASHBOARDS_REQUEST
    case actions.GET_USER_PUBLIC_DASHBOARDS_REQUEST:
      return {
        ...state,
        initialState,
        user_public_dashboards: []
      }

    // GET_USER_PUBLIC_DASHBOARDS_SUCCESS
    case actions.GET_USER_PUBLIC_DASHBOARDS_SUCCESS:
      return {
        ...state,
        user_public_dashboards: action.response
      }

    // GET_USER_PUBLIC_DASHBOARDS_FAILURE
    case actions.GET_USER_PUBLIC_DASHBOARDS_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // GET_USER_PRIVATE_DASHBOARDS_REQUEST
    case actions.GET_USER_PRIVATE_DASHBOARDS_REQUEST:
      return {
        ...state,
        initialState,
        user_private_dashboards: []
      }

    // GET_USER_PRIVATE_DASHBOARDS_SUCCESS
    case actions.GET_USER_PRIVATE_DASHBOARDS_SUCCESS:
      return {
        ...state,
        user_private_dashboards: action.response
      }

    // GET_USER_PRIVATE_DASHBOARDS_FAILURE
    case actions.GET_USER_PRIVATE_DASHBOARDS_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // UPDATE REPORT REQUEST
    case actions.UPDATE_DASHBOARD_REQUEST:
      return {
        ...state,
        initialState
      }

    // UPDATE REPORT SUCCESS
    case actions.UPDATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        id: action.id,
        name: action.name,
        report_id: action.report_id,
        workspace_id: action.workspace_id,
        platform: action.platform_id,
        sectors_old: action.sectors_old,
        sectors_new: action.sectors_new
      }

    // UPDATE REPORT FAILURE
    case actions.UPDATE_DASHBOARD_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // DELETE REPORT REQUEST
    case actions.DELETE_DASHBOARD_REQUEST:
      return {
        ...state,
        initialState
      }

    // DELETE REPORT SUCCESS
    case actions.DELETE_DASHBOARD_SUCCESS:
      return {
        ...state
      }

    // DELETE REPORT FAILURE
    case actions.DELETE_DASHBOARD_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    default:
      return state
  }
}

export default reducer
