// NPM imports
import React from 'react'
import PropTypes from 'prop-types'
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P1,
  P2,
  Button1,
  Button2,
  Caption,
  Overline
} from './styles'
/* eslint-disable react/jsx-props-no-spreading */

const Text = (props) => {
  switch (props?.type?.toLowerCase()) {
    case 'h1':
      return <H1 {...props}>{props.children}</H1>
    case 'h2':
      return <H2 {...props}>{props.children}</H2>
    case 'h3':
      return <H3 {...props}>{props.children}</H3>
    case 'h4':
      return <H4 {...props}>{props.children}</H4>
    case 'h5':
      return <H5 {...props}>{props.children}</H5>
    case 'h6':
      return <H6 {...props}>{props.children}</H6>
    case 'p1':
      return <P1 {...props}>{props.children}</P1>
    case 'p2':
      return <P2 {...props}>{props.children}</P2>
    case 'button1':
      return <Button1 {...props}>{props.children}</Button1>
    case 'button2':
      return <Button2 {...props}>{props.children}</Button2>
    case 'caption':
      return <Caption {...props}>{props.children}</Caption>
    case 'overline':
      return <Overline {...props}>{props.children}</Overline>
    default:
      return <P1 {...props}>{props.children}</P1>
  }
}

Text.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  type: PropTypes.oneOf([
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'P1',
    'P2',
    'Button1',
    'Button2',
    'Caption',
    'Overline'
  ]),
  isBold: PropTypes.bool
}

Text.defaultProps = {
  color: '',
  type: 'H1',
  children: null,
  isBold: false
}

export default Text
