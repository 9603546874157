import styled from 'styled-components'
import { fonts } from 'styles/theme'

export const H1 = styled.h1`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.titleFamily};
  font-size: 32px;
  font-weight: ${(props) => (props.isBold ? 500 : 300)};
  line-height: 40px;
`

export const H2 = styled.h2`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.titleFamily};
  font-size: 24px;
  font-weight: ${(props) => (props.isBold ? 500 : 300)};
  line-height: 40px;
  margin-bottom: 15px;
`

export const H3 = styled.h3`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.titleFamily};
  font-size: 20px;
  font-weight: ${(props) => (props.isBold ? 500 : 300)};
  line-height: 32px;
`

export const H4 = styled.h4`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.titleFamily};
  font-size: 18px;
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
  line-height: 28px;
`

export const H5 = styled.h5`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.titleFamily};
  font-size: 16px;
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
  line-height: 24px;
`

export const H6 = styled.h6`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.titleFamily};
  font-size: 14px;
  font-weight: ${(props) => (props.isBold ? 500 : 'normal')};
  line-height: 20px;
`

export const P1 = styled.p`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.textFamily};
  font-size: 15px;
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
  line-height: 24px;
`

export const P2 = styled.p`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.textFamily};
  font-size: 14px;
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
  line-height: 20px;
`

export const Button1 = styled.span`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.titleFamily};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`

export const Button2 = styled.span`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.titleFamily};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`

export const Caption = styled.p`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.textFamily};
  font-size: 12px;
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
  line-height: 16px;
`

export const Overline = styled.span`
  margin: 0;
  color: ${props => props.color || 'inherit'};
  font-family: ${fonts.textFamily};
  font-size: 10px;
  font-weight: ${(props) => (props.isBold ? 500 : 'normal')};
  line-height: 16px;
`
