// Project imports
import http from 'util/http'

// Action Types
const GET_ALL_USERS_REQUEST = 'user/GET_ALL_USERS_REQUEST'
const GET_ALL_USERS_SUCCESS = 'user/GET_ALL_USERS_SUCCESS'
const GET_ALL_USERS_FAILURE = 'user/GET_ALL_USERS_FAILURE'

const UPDATE_USER_REQUEST = 'user/UPDATE_USER_REQUEST'
const UPDATE_USER_SUCCESS = 'user/UPDATE_USER_SUCCESS'
const UPDATE_USER_FAILURE = 'user/UPDATE_USER_FAILURE'

export const actions = {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,

  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE
}

/* ACTION CREATORS */
// GET ALL USERS
function getAllUsersRequest() {
  return { type: GET_ALL_USERS_REQUEST }
}

export function getAllUsersSuccess(response) {
  return {
    type: GET_ALL_USERS_SUCCESS,
    response
  }
}

function getAllUsersFailure(error) {
  return {
    type: GET_ALL_USERS_FAILURE,
    error
  }
}

// UPDATE
function updateUserRequest() {
  return { type: UPDATE_USER_REQUEST }
}

export function updateUserSuccess(response) {
  return {
    type: UPDATE_USER_SUCCESS,
    response
  }
}

function updateUserFailure(error) {
  return {
    type: UPDATE_USER_FAILURE,
    error
  }
}

/* THUNK ACTION CREATORS */
// GET ALL USERS ACTION
export function getAllUsers() {
  return async dispatch => {
    dispatch(getAllUsersRequest())
    try {
      const response = await http({
        method: 'GET',
        url: '/users/all'
      })
      const { data } = response
      dispatch(getAllUsersSuccess(data))
      return data
    } catch (error) {
      dispatch(getAllUsersFailure(error))
      return null
    }
  }
}

// UPDATE ACTION
export function updateUser(values) {
  return async dispatch => {
    dispatch(updateUserRequest())
    try {
      const response = await http({
        method: 'PUT',
        url: `/user/${values.id}`,
        data: {
          role: values.roleId
        }
      })
      dispatch(updateUserSuccess(response))
      return response.status
    } catch (error) {
      dispatch(updateUserFailure(error))
      return null
    }
  }
}
