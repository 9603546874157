import styled from 'styled-components'
import ProfilePicture from 'camino-web/components/ProfilePicture'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 72px;
  grid-area: head;
  background-color: #FFF;
  box-shadow: 4px 4px 10px 0px #d1d1d1;
  z-index: 1;
`

export const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  flex: ${(props) => (props.hasTitle ? '0' : '1')};
  margin: 0 30px;
`
export const SButton = styled.button`
  display: flex;
  justify-items: center;
  align-items: center;
  border: none;
  background: none;
  outline: none;
`

export const SProfilePicture = styled(ProfilePicture)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;

  background-color: ${(props) => (props.bgColor ? props.bgColor : '')};
`
export const MenuOptions = styled.div`
  position: relative;
`
