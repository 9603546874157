// NPM imports

import { useState, useEffect } from 'react'
import { v1 as uuidv1 } from 'uuid'

// shared state

let listeners = []
let messages = []

function broadcast() {
  listeners.forEach((listener) => {
    listener(messages)
  })
}

/**
 * add a message object into toast messages array
 *
 * @param {string} text- toast error message
 * @param {string} type - one of default, alert or success
 * @param {boolean} preventAutoDismiss
 */
function addToast(text, config = {}) {
  const {
    id = uuidv1(),
    type = 'success',
    currentFile = 0, // index start 0
    totalFiles = 1,
    preventAutoDismiss = false,
    action
  } = config

  const newMessage = {
    id,
    text,
    currentFile,
    type,
    totalFiles,
    preventAutoDismiss,
    action
  }
  messages = [...messages, newMessage]
  broadcast()
}

function clearToast(id) {
  messages = messages.filter((msg) => msg.id !== id)
  broadcast()
}

// hook

export function useToast() {
  const newListener = useState()[1]
  useEffect(() => {
    // Called just after component mount
    listeners.push(newListener)
    return () => {
      // Called just before the component unmount
      listeners = listeners.filter((listener) => listener !== newListener)
    }
  }, [newListener])
  return { messages, addToast, clearToast }
}
