import React from 'react'
import styled from 'styled-components'
import Text from 'camino-web/components/Text'
import { useAuthenticator } from '@aws-amplify/ui-react'

// Styled
const Container = styled.div`
  padding: 20px;
`

const Home = () => {
  const { user } = useAuthenticator(context => [context.user])
  const { name } = user.getSignInUserSession().getIdToken().payload

  return (
    <Container>
      <Text type="H2">
        Olá,
        <b>
          { ' ' }
          { name?.split(' ', 1) }
          { '!' }
        </b>
      </Text>
    </Container>
  )
}

export default Home
