// NPM imports
import 'App.css'
import React, { useState, useRef } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'

// projects imports
import { colors } from 'styles/theme'
import SettingOptionsModal from 'camino-web/components/SettingOptionsModal'
import * as S from './styles'

const Header = () => {
  const { user } = useAuthenticator(context => [context.user])
  const { name, picture } = user.getSignInUserSession().getIdToken().payload

  // user menu options
  const [isShowingMenuOptions, setIsShowingMenuOptions] = useState(false)
  const menuOptionsRef = useRef(null)

  return (
    <>
      <S.Container className="header">
        <S.RightContent>
          <S.SButton type="button" onClick={() => {}}>
            <S.MenuOptions
              ref={menuOptionsRef}
              onClick={() => setIsShowingMenuOptions((showing) => !showing)}
              id="settings"
            >
              {isShowingMenuOptions ? (
                <S.SProfilePicture
                  size={48}
                  fullName="X"
                  hasBorder
                  bgColor={colors.grayLighter}
                />
              ) : (
                <S.SProfilePicture
                  imgUrl={picture}
                  size={48}
                  fullName={name}
                  hasBorder
                />
              )}
            </S.MenuOptions>
          </S.SButton>
          <SettingOptionsModal
            isShowing={isShowingMenuOptions}
            onClose={() => setIsShowingMenuOptions(false)}
            parent={menuOptionsRef}
          />
        </S.RightContent>
      </S.Container>
    </>
  )
}

export default Header
