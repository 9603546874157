import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Text from 'camino-web/components/Text'
import { getAllSectors, addSector } from 'store/actions/sector'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { useToast } from 'camino-web/components/Toast'
import * as S from './styles'

const AddSector = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast } = useToast()

  const { sectors } = useSelector(state => state.sector)

  useEffect(() => {
    dispatch(getAllSectors())
  }, [dispatch])

  const initialValues = {
    sectorName: ''
  }

  const createSector = async (values, { resetForm }) => {
    try {
      const response = await dispatch(addSector(values))
      if (response !== 201) {
        addToast('Erro - Falha ao cadastrar setor. Tente novamente!', { type: 'alert' })
      } else {
        addToast('Setor cadastrado com Sucesso!', { type: 'success' })
      }
      resetForm({ values: initialValues })
      history.push('/addSector')
    } catch (error) {
      console.error(error.message)
      addToast(error.message, { type: 'alert' })
    }
  }

  function validateName(sectorName) {
    let error

    if (!sectorName) {
      error = 'Insira um nome.'
    } else if (sectors.some(item => item.name === sectorName)) {
      error = 'Esse nome já existe!'
    }

    return error
  }

  return (
    <S.Container>
      <Text type="H2">Cadastro de Setor</Text>
      <Formik
        initialValues={initialValues}
        onSubmit={createSector}
      >
        {() => (
          <Form>
            <S.Label htmlFor="sectorName">Nome do Setor</S.Label>
            <ErrorMessage name="sectorName" component={S.FieldErrorMessage} />
            <Field
              id="sectorName"
              name="sectorName"
              placeholder="Financeiro"
              as={S.Input}
              autoComplete="off"
              validate={validateName}
              required
            />

            <S.BtnSubmit type="submit" text="Cadastrar Setor" />
          </Form>
        )}
      </Formik>
    </S.Container>
  )
}

export default AddSector
