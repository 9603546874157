// Project imports
import { actions } from '../actions/user'

const initialState = {
  id: null,
  name: '',
  role_id: null,
  users: [],
  error: ''
}

function reducer(state = initialState, action) {
  switch (action.type) {
    // GET_ALL_USERS REQUEST
    case actions.GET_ALL_USERS_REQUEST:
      return {
        ...state,
        initialState
      }

    // GET_ALL_USERS SUCCESS
    case actions.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.response
      }

    // GET_ALL_USERS FAILURE
    case actions.GET_ALL_USERS_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // GET USER BY ID REQUEST
    case actions.GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        initialState
      }

    // GET USER BY ID SUCCESS
    case actions.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        id: action.id,
        name: action.name,
        role_id: action.role_id
      }

    // GET USER BY ID FAILURE
    case actions.GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // UPDATE USER REQUEST
    case actions.UPDATE_USER_REQUEST:
      return {
        ...state,
        initialState
      }

    // UPDATE USER SUCCESS
    case actions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        id: action.id,
        name: action.name,
        role_id: action.role_id
      }

    // UPDATE USER FAILURE
    case actions.UPDATE_USER_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    default:
      return state
  }
}

export default reducer
