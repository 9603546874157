import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Text from 'camino-web/components/Text'
import { SelectField } from 'components/Form'
import { getAllUsers, updateUser } from 'store/actions/user'
import { getAllRoles } from 'store/actions/role'
import { Field, Form, Formik } from 'formik'
import { useToast } from 'camino-web/components/Toast'
import * as S from './styles'

const EditUser = () => {
  const dispatch = useDispatch()
  const { addToast } = useToast()
  const [editUser, setEditUser] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)

  const { users } = useSelector(state => state.user)
  const { roles } = useSelector(state => state.role)

  useEffect(() => {
    dispatch(getAllRoles())
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllUsers())
  }, [dispatch])

  const openModal = (user, modal) => {
    setEditUser(user)

    if (modal === 'edit') {
      setShowModalEdit(prev => !prev)
    }
  }

  const updateUserRequest = async (values) => {
    setShowModalEdit(false)
    try {
      const response = await dispatch(updateUser(values))
      if (response !== 200) {
        addToast('Erro - Falha ao editar usuário. Tente novamente!', { type: 'alert' })
      } else {
        dispatch(getAllUsers())
        addToast('Usuário alterado com Sucesso!', { type: 'success' })
      }
    } catch (error) {
      addToast(error.message, { type: 'alert' })
    }
  }

  function renderContent() {
    if (!users) {
      return null
    }
    return (
      <>
        <Text type="H2">Edição de Usuários</Text>
        <>
          <S.Table>
            <tbody>
              <S.TR>
                <S.TH>Nome</S.TH>
                <S.TH>Email</S.TH>
                <S.TH>Role</S.TH>
                <S.TH>#</S.TH>
              </S.TR>
              {users.map((user) => (
                <S.TR key={user.id}>
                  <S.TD>{user.name}</S.TD>
                  <S.TD>{user.email}</S.TD>
                  <S.TD>{user.role.name}</S.TD>
                  <S.TD>
                    <S.SButton onClick={() => openModal(user, 'edit')}>
                      <S.SIcon name="create" />
                    </S.SButton>
                  </S.TD>
                </S.TR>
              ))}
            </tbody>
          </S.Table>
        </>
      </>
    )
  }

  return (
    <S.Container>
      {renderContent()}

      <S.Modal open={showModalEdit}>
        <S.ModalContent>
          <S.ModalHeader>
            <Text type="H2">Edição de Usuário </Text>
            <S.Close type="button" onClick={() => setShowModalEdit(false)}>&times;</S.Close>
          </S.ModalHeader>
          <S.ModalBody>
            <Formik
              enableReinitialize
              initialValues={{
                id: editUser.id,
                name: editUser.name,
                roleId: editUser.role?.id
              }}
              onSubmit={updateUserRequest}
            >
              <Form>
                <S.Label htmlFor="name">Nome do Usuário</S.Label>
                <S.SText type="P1">
                  <b>{ editUser.name }</b>
                </S.SText>

                <S.Label htmlFor="email">Email do Usuário</S.Label>
                <S.SText type="P1">
                  <b>{ editUser.email }</b>
                </S.SText>

                <S.DivSelect>
                  <S.Label htmlFor="roles">Role</S.Label>
                  <Field
                    name="roleId"
                    placeholder="Selecione..."
                    maxLength={72}
                    component={SelectField}
                    options={roles.map(({ id, name }) => ({ label: name, value: id }))}
                    value={editUser.role?.id}
                  />
                </S.DivSelect>

                <S.ModalFooter>
                  <S.SecondaryActionButton text="Cancelar" onClick={() => setShowModalEdit(false)} />
                  <S.PrimaryActionButton text="Salvar" type="submit" />
                </S.ModalFooter>
              </Form>
            </Formik>
          </S.ModalBody>
        </S.ModalContent>
      </S.Modal>
    </S.Container>
  )
}

export default EditUser
