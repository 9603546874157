import { SelectField } from 'components/Form'
import Text from 'camino-web/components/Text'
import { useToast } from 'camino-web/components/Toast'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getAllDashboards, addDashboard } from 'store/actions/dashboard'
import { getAllSectors } from 'store/actions/sector'
import { getAllUsers } from '../../../store/actions/user'
import * as S from './styles'

const AddDashboard = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [typeRadio, setTypeRadio] = useState('public')
  const { addToast } = useToast()

  const { dashboards } = useSelector(state => state.dashboard)
  const { sectors } = useSelector(state => state.sector)
  const { users } = useSelector(state => state.user)

  useEffect(() => {
    dispatch(getAllDashboards())
    dispatch(getAllSectors())
    dispatch(getAllUsers())
  }, [dispatch])

  const initialValues = {
    dashboardName: '',
    reportId: '',
    workspaceId: '',
    platformId: 1,
    isPrivate: '',
    sectorId: '',
    ownerId: '',
    users: []
  }

  /* eslint no-param-reassign: "error" */
  const createDashboard = async (values, { resetForm }) => {
    console.log(values)
    try {
      if (typeRadio === 'public') {
        values.isPrivate = false // Public
      } else if (typeRadio === 'private') {
        values.isPrivate = true // Private
      }

      const response = await dispatch(addDashboard(values))
      if (response !== 201) {
        addToast('Erro - Falha ao cadastrar Dashboard. Tente novamente!', { type: 'alert' })
      } else {
        addToast('Dashboard cadastrado com Sucesso!', { type: 'success' })
      }
      resetForm({ values: initialValues })
      history.push('/addDashboard')
    } catch (error) {
      addToast(error.message, { type: 'alert' })
    }
  }

  function validateName(value) {
    let error

    if (!value) {
      error = 'Insira um nome.'
    } else if (dashboards.some(item => item.name === value)) {
      error = 'Esse nome já existe!'
    }

    return error
  }

  function validateReportID(value) {
    let error
    const re = new RegExp('^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$')

    if (!value) {
      error = 'Insira um ID de Report.'
    } else if (!re.test(value)) {
      error = 'ID de Report inválido!'
    } else if (dashboards.some(item => item.report_id === value)) {
      error = 'Esse ID de Report já existe!'
    }

    return error
  }

  function validateWorkspaceID(value) {
    let error
    const re = new RegExp('^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$')

    if (!value) {
      error = 'Insira um ID de Workspace.'
    } else if (!re.test(value)) {
      error = 'ID de Workspace inválido!'
    }

    return error
  }

  function validateSector(value) {
    let error

    if (!value) {
      error = 'Selecione um sector.'
    }

    return error
  }

  function validateOwner(value) {
    let error

    if (!value) {
      error = 'Selecione um responsável.'
    }

    return error
  }

  function validatePrivateUsers(list) {
    let error

    if (!list.length) {
      error = 'Selecione pelo menos um usuário.'
    }

    return error
  }

  function renderPrivacy(type) {
    let element
    if (type === 'private') {
      element = (
        <>
          <S.Label htmlFor="users">Usuários permitidos</S.Label>
          <ErrorMessage name="users" component={S.FieldErrorMessage} />
          <S.DivSelect>
            <Field
              name="users"
              placeholder="Selecione..."
              maxLength={72}
              component={SelectField}
              options={users.map(({ email, name }) => ({ label: name, value: email }))}
              isMulti
              validate={validatePrivateUsers}
              required
            />
          </S.DivSelect>
        </>
      )
    }

    return (
      element
    )
  }

  const onChange = (e) => {
    setTypeRadio(e.target.value)
  }

  return (
    <S.Container>
      <Text type="H2">Cadastro de Dashboard</Text>
      <Formik
        initialValues={initialValues}
        onSubmit={createDashboard}
      >
        {() => (
          <Form>
            <S.Label htmlFor="dashboardName">Nome do Dashboard</S.Label>
            <ErrorMessage name="dashboardName" component={S.FieldErrorMessage} />
            <Field
              name="dashboardName"
              as={S.Input}
              autoComplete="off"
              validate={validateName}
              required
            />

            <S.Label htmlFor="reportId">ID do Relatório</S.Label>
            <ErrorMessage name="reportId" component={S.FieldErrorMessage} />
            <Field
              name="reportId"
              as={S.Input}
              autoComplete="off"
              validate={validateReportID}
              required
            />

            <S.Label htmlFor="workspaceId">ID do Workspace</S.Label>
            <ErrorMessage name="workspaceId" component={S.FieldErrorMessage} />
            <Field
              name="workspaceId"
              as={S.Input}
              autoComplete="off"
              validate={validateWorkspaceID}
              required
            />

            <S.Label htmlFor="sectorId">Setor</S.Label>
            <ErrorMessage name="sectorId" component={S.FieldErrorMessage} />
            <S.DivSelect>
              <Field
                name="sectorId"
                placeholder="Selecione..."
                maxLength={72}
                component={SelectField}
                options={sectors.map(({ id, name }) => ({ label: name, value: id }))}
                validate={validateSector}
                required
              />
            </S.DivSelect>

            <S.Label htmlFor="ownerId">Responsável</S.Label>
            <ErrorMessage name="ownerId" component={S.FieldErrorMessage} />
            <S.DivSelect>
              <Field
                name="ownerId"
                placeholder="Selecione..."
                maxLength={72}
                component={SelectField}
                options={users.map(({ email, name }) => ({ label: name, value: email }))}
                validate={validateOwner}
                required
              />
            </S.DivSelect>

            <S.Label>
              Tipo do Dashboard
            </S.Label>

            <S.RadioGroup htmlFor="isPrivate">
              <S.RadioItem>
                <Field
                  name="isPrivate"
                  type="radio"
                  value="public"
                  checked={typeRadio === 'public'}
                  onChange={onChange}
                />
                Público
              </S.RadioItem>

              <S.RadioItem>
                <Field
                  name="isPrivate"
                  type="radio"
                  value="private"
                  checked={typeRadio === 'private'}
                  onChange={onChange}
                />
                Privado
              </S.RadioItem>
            </S.RadioGroup>

            {renderPrivacy(typeRadio)}

            <S.BtnSubmit type="submit" text="Cadastrar Dashboard" />

          </Form>
        )}
      </Formik>
    </S.Container>
  )
}

export default AddDashboard
