// NPM imports
import React from 'react'
import GoogleBtn from 'components/GoogleBtn'
import camino from 'img/logo-color-camino.png'
import Text from 'camino-web/components/Text'
import * as S from './styles'

const Login = () => (
  <S.ContainerLogin>
    <S.ContentLogin>
      <S.CloeLogoContainer>
        <S.CloeLogo src={camino} />
      </S.CloeLogoContainer>
      <Text type="P2">Faça Login:</Text>
      <GoogleBtn />
    </S.ContentLogin>
  </S.ContainerLogin>
)

export default Login
