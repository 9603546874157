// NPM imports
import React from 'react'
import PropTypes from 'prop-types'

// Project imports
import { iconProps } from '../Icon'
import {
  Container,
  SIcon,
  Text,
  Spinner
} from './styles'

function Button({
  className,
  text,
  isLoading,
  disabled,
  leftIcon,
  rightIcon,
  ...props
}) {
  return (
    <Container
      className={className}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      /* eslint-disable react/jsx-props-no-spreading */
      {...props}
    >
      {leftIcon && (
        <SIcon
          {...leftIcon}
          css="margin-right: 8px;"
        />
      )}
      <Text>
        {text}
      </Text>
      {rightIcon && (
        <SIcon
          {...rightIcon}
          css="margin-left: 8px;"
        />
      )}
      {isLoading && (
        <Spinner />
      )}
    </Container>
  )
}

export const buttonProps = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  outline: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  isAlert: PropTypes.bool,
  isGhost: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  leftIcon: PropTypes.shape(iconProps),
  rightIcon: PropTypes.shape(iconProps),
  isLoading: PropTypes.bool
}

Button.propTypes = buttonProps
Button.defaultProps = {
  className: '',
  active: false,
  disabled: false,
  secondary: false,
  outline: false,
  size: null,
  isAlert: false,
  isGhost: false,
  onClick: () => { },
  type: 'button',
  leftIcon: null,
  rightIcon: null,
  isLoading: false
}

export default Button
