// NPM imports
import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import dashboard from './reducers/dashboard'
import role from './reducers/role'
import sector from './reducers/sector'
import user from './reducers/user'

const rootReducers = combineReducers({
  dashboard,
  role,
  sector,
  user
})

const store = createStore(rootReducers, applyMiddleware(thunkMiddleware))

export default store
