// NPM imports
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { RemoveScroll } from 'react-remove-scroll'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { colors, fonts, shadow } from 'styles/theme'
import { contentWidth, mediaQuery } from 'styles/responsive'

// Project imports
import Button from './Buttons'

// Styled components

const animDuration = 300

const Backdrop = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px 40px;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity ${animDuration}ms;

  &.actionsheet-anim-enter {
    opacity: 0;
  }
  &.actionsheet-anim-enter.actionsheet-anim-enter-active {
    opacity: 1;
  }

  &.actionsheet-anim-exit {
    opacity: 1;
  }
  &.actionsheet-anim-exit.actionsheet-anim-exit-active {
    opacity: 0;
  }
`

const Panel = styled.div`
  width: ${contentWidth.sm};
  max-width: 100%;
  padding: 24px;
  border-radius: 8px;
  background: white;
  box-shadow: ${shadow.dialogBox};
  transform: translateY(0);
  transition: transform ${animDuration}ms;

  ${Backdrop}.actionsheet-anim-enter & {
    transform: translateY(40px);
  }
  ${Backdrop}.actionsheet-anim-enter.actionsheet-anim-enter-active & {
    transform: translateY(0);
  }

  ${Backdrop}.actionsheet-anim-exit & {
    transform: translateY(0);
  }
  ${Backdrop}.actionsheet-anim-exit.actionsheet-anim-exit-active & {
    transform: translateY(40px);
  }
`

const Title = styled.h6`
  margin: 0 0 16px;
  text-align: center;
  width: 100%;
  font-family: ${fonts.titleFamily};
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: ${colors.grayDarker};
  @media ${mediaQuery.minSm} {
    text-align: left;
  }
`

const Text = styled.p`
  margin: 0;
  font-family: ${fonts.textFamily};
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.grayDarker};
  text-align: center;
  width: 100%;
  @media ${mediaQuery.minSm} {
    text-align: left;
    padding-right: 24px;
  }
`

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  @media ${mediaQuery.minSm} {
    flex-direction: row-reverse;
  }
`

const PrimaryActionButton = styled(Button)`
  background: ${props => props.background || colors.brandSecondary};
  color: white;
  border: none;
  width: 100%;
  @media ${mediaQuery.minSm} {
    width: auto;
    margin-left: 8px;
  }
`

const SecondaryActionButton = styled(Button)`
  background: white;
  color: ${props => props.color || colors.gray};
  width: 100%;
  border: none;
  margin-top: 8px;
  @media ${mediaQuery.minSm} {
    width: auto;
    margin-top: 0;
    border: 1px solid ${props => props.color || colors.gray};
  }
`

// const SpaceElem = styled.span`
//   width: 0px;
//   @media ${mediaQuery.minSm} {
//     flex-grow: 2;
//   }
// `;

// Component

function ConfirmationModal({
  className,
  title,
  text,
  primaryAction,
  secondaryAction,
  isShowing,
  onBackdropClick
}) {
  return (
    <RemoveScroll enabled={isShowing}>
      <TransitionGroup component={null} appear>
        {isShowing && (
          <CSSTransition classNames="actionsheet-anim" timeout={animDuration}>
            <Backdrop onClick={onBackdropClick}>
              <Panel
                className={className}
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                {title && <Title>{title}</Title>}
                <Text>{text}</Text>
                <Actions>
                  {/* <SpaceElem /> */}
                  <PrimaryActionButton
                    text={primaryAction.text}
                    color={primaryAction.color}
                    background={primaryAction.background}
                    onClick={primaryAction.onClick}
                  />
                  {secondaryAction && (
                    <SecondaryActionButton
                      text={secondaryAction.text}
                      color={secondaryAction.color}
                      background="white"
                      onClick={secondaryAction.onClick}
                    />
                  )}
                </Actions>
              </Panel>
            </Backdrop>
          </CSSTransition>
        )}
      </TransitionGroup>
    </RemoveScroll>
  )
}

ConfirmationModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  primaryAction: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
    background: PropTypes.node
  }).isRequired,
  secondaryAction: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string
  }),
  isShowing: PropTypes.bool,
  onBackdropClick: PropTypes.func
}

ConfirmationModal.defaultProps = {
  className: '',
  title: null,
  secondaryAction: null,
  isShowing: PropTypes.bool,
  onBackdropClick: null
}

export default ConfirmationModal
