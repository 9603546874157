import styled from 'styled-components'
import { colors } from 'styles/theme'
import { InputField } from 'components/Form'
import Button from 'camino-web/components/Buttons'

export const Container = styled.div`
  padding: 20px;
`

export const Label = styled.label`
`

export const BtnSubmit = styled(Button)`
  margin: 15px 0;
`

export const Input = styled(InputField)`
  width: 50%;
  margin: 3px 0 10px 0;
`

export const DivSelect = styled.div`
  width: 50%;
  margin: 3px 0 10px 0;
`

export const RadioGroup = styled.div`
  padding: 8px 0;
`

export const RadioItem = styled.label`
  padding: 0 4px;
`

export const FieldErrorMessage = styled.div`
  display: inline;
  padding: 8px;
  border-radius: 4px;
  margin-top: 4px;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.alert};
`
