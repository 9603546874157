// Project imports
import http from 'util/http'

// Action Types
const GET_ALL_ROLES_REQUEST = 'role/GET_ALL_ROLES_REQUEST'
const GET_ALL_ROLES_SUCCESS = 'role/GET_ALL_ROLES_SUCCESS'
const GET_ALL_ROLES_FAILURE = 'role/GET_ALL_ROLES_FAILURE'
const GET_ALLOWED_MODULES_REQUEST = 'role/GET_ALLOWED_MODULES_REQUEST'
const GET_ALLOWED_MODULES_SUCCESS = 'role/GET_ALLOWED_MODULES_SUCCESS'
const GET_ALLOWED_MODULES_FAILURE = 'role/GET_ALLOWED_MODULES_FAILURE'

export const actions = {
  GET_ALL_ROLES_REQUEST,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE,
  GET_ALLOWED_MODULES_REQUEST,
  GET_ALLOWED_MODULES_SUCCESS,
  GET_ALLOWED_MODULES_FAILURE
}

/* ACTION CREATORS */
// GET ALL ROLES
function getAllRolesRequest() {
  return { type: GET_ALL_ROLES_REQUEST }
}

export function getAllRolesSuccess(response) {
  return {
    type: GET_ALL_ROLES_SUCCESS,
    response
  }
}

function getAllRolesFailure(error) {
  return {
    type: GET_ALL_ROLES_FAILURE,
    error
  }
}

export function getAllowedModulesRequest() {
  return { type: GET_ALLOWED_MODULES_REQUEST }
}

export function getAllowedModulesSuccess(data) {
  return {
    type: GET_ALLOWED_MODULES_SUCCESS,
    data
  }
}

function getAllowedModulesFailure(error) {
  return {
    type: GET_ALLOWED_MODULES_FAILURE,
    error
  }
}

// GET ALL ROLES ACTION
export function getAllRoles() {
  return async (dispatch) => {
    dispatch(getAllRolesRequest())
    try {
      const response = await http({
        method: 'GET',
        url: '/roles/all'
      })
      const { data } = response
      dispatch(getAllRolesSuccess(data))
      return data
    } catch (error) {
      dispatch(getAllRolesFailure(error))
      return null
    }
  }
}

export function getAllowedModules() {
  return async (dispatch) => {
    dispatch(getAllowedModulesRequest())
    try {
      const response = await http({
        method: 'GET',
        url: '/roles/allowed-modules'
      })
      dispatch(getAllowedModulesSuccess(response.data))
      return response
    } catch (error) {
      dispatch(getAllowedModulesFailure(error))
      return null
    }
  }
}
