import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Text from 'camino-web/components/Text'
import { getAllSectors, updateSector, deleteSector } from 'store/actions/sector'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useToast } from 'camino-web/components/Toast'
import _ from 'lodash'
import * as S from './styles'

const EditSector = () => {
  const dispatch = useDispatch()
  const { addToast } = useToast()
  const [editSector, setEditSector] = useState(false)
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)

  const { sectors } = useSelector(state => state.sector)

  useEffect(() => {
    dispatch(getAllSectors())
  }, [dispatch])

  const openModal = (sector, modal) => {
    setEditSector(_.cloneDeep(sector))

    if (modal === 'edit') {
      setShowModalEdit(prev => !prev)
    } else if (modal === 'delete') {
      setShowModalDelete(prev => !prev)
    }
  }

  const updateSectorRequest = async (values) => {
    setShowModalEdit(false)
    try {
      const response = await dispatch(updateSector(values))
      if (response !== 200) {
        addToast('Erro - Falha ao editar setor. Tente novamente!', { type: 'alert' })
      } else {
        dispatch(getAllSectors())
        addToast('Setor alterado com Sucesso!', { type: 'success' })
      }
    } catch (error) {
      addToast(error.message, { type: 'alert' })
    }
  }

  const deleteSectorRequest = async (value) => {
    setShowModalDelete(false)
    try {
      const response = await dispatch(deleteSector(value))
      if (response !== 200) {
        addToast('Erro - Falha ao excluir Setor. Tente novamente!', { type: 'alert' })
      } else {
        dispatch(getAllSectors())
        addToast('Setor excluído com Sucesso!', { type: 'success' })
      }
    } catch (error) {
      addToast(error.message, { type: 'alert' })
    }
  }

  function validateName(sectorName) {
    let error

    if (!sectorName) {
      error = 'Insira um nome.'
    } else if (sectors.some(item => item.name === sectorName)) {
      error = 'Esse nome já existe!'
    }

    return error
  }

  function renderContent() {
    if (!sectors) {
      return null
    }
    return (
      <>
        <Text type="H2">Edição de Setores</Text>
        <>
          <S.Table>
            <tbody>
              <S.TR>
                <S.TH>Nome</S.TH>
                <S.TH>#</S.TH>
              </S.TR>
              {sectors.map((sector) => (
                <S.TR key={sector.id}>
                  <S.TD>{sector.name}</S.TD>
                  <S.TD>
                    <S.SButton onClick={() => openModal(sector, 'edit')}>
                      <S.SIcon name="create" />
                    </S.SButton>
                    <S.SButton onClick={() => openModal(sector, 'delete')}>
                      <S.SIcon name="delete" />
                    </S.SButton>
                  </S.TD>
                </S.TR>
              ))}
            </tbody>
          </S.Table>
        </>
      </>
    )
  }

  return (
    <S.Container>
      {renderContent()}

      <S.Modal open={showModalEdit}>
        <S.ModalContent>
          <S.ModalHeader>
            <Text type="H2">Edição de Setor</Text>
            <S.Close type="button" onClick={() => setShowModalEdit(false)}>&times;</S.Close>
          </S.ModalHeader>
          <S.ModalBody>
            <Formik
              enableReinitialize
              initialValues={{
                id: editSector.id,
                name: editSector.name
              }}
              onSubmit={updateSectorRequest}
            >
              <Form>
                <S.Label htmlFor="name">Nome do Setor</S.Label>
                <ErrorMessage name="name" component={S.FieldErrorMessage} />
                <Field
                  id="name"
                  name="name"
                  as={S.Input}
                  validate={validateName}
                  autoComplete="off"
                  required
                />

                <S.ModalFooter>
                  <S.SecondaryActionButton text="Cancelar" onClick={() => setShowModalEdit(false)} />
                  <S.PrimaryActionButton text="Salvar" type="submit" />
                </S.ModalFooter>
              </Form>
            </Formik>
          </S.ModalBody>
        </S.ModalContent>
      </S.Modal>

      <S.Modal open={showModalDelete}>
        <S.ModalContent>
          <S.ModalHeader>
            <Text type="H2">Exclusão de Setores </Text>
            <S.Close type="button" onClick={() => setShowModalDelete(false)}>&times;</S.Close>
          </S.ModalHeader>
          <S.ModalBody>
            <Formik
              enableReinitialize
              initialValues={{
                id: editSector.id,
                name: editSector.name
              }}
              onSubmit={() => {}}
            >
              <Form>
                <S.Label id="id" name="name">
                  Deseja realmente excluir permanentemente o Setor
                  { ' ' }
                  <b>{ editSector.name }</b>
                  ?
                </S.Label>
              </Form>
            </Formik>
          </S.ModalBody>
          <S.ModalFooter>
            <S.SecondaryActionButton text="Cancelar" onClick={() => setShowModalDelete(false)} />
            <S.PrimaryActionButton text="Excluir" onClick={() => deleteSectorRequest(editSector.id)} />
          </S.ModalFooter>
        </S.ModalContent>
      </S.Modal>
    </S.Container>
  )
}

export default EditSector
