import styled from 'styled-components'
import { colors, fonts, shadow } from 'styles/theme'
import { InputField, SelectField } from 'components/Form'
import Text from 'camino-web/components/Text'
import Icon from 'camino-web/components/Icon'
import Button from 'camino-web/components/Buttons'

export const Modal = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
`

export const ModalContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  box-shadow: ${shadow.cardLg};
  margin-left: 28%;
`

export const Close = styled.button`
  background: none;
  color: #aaaaaa;
  float: right;
  font-size: 24px;
  border: 0;
  outline: none;
  padding: 0;

  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const ModalBody = styled.div`
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0 0;
`

export const Container = styled.div`
  padding: 20px;
`

export const SText = styled(Text)`
  padding-bottom: 5px;
`

export const SButton = styled.button`
  outline: none;
  background-color: #FFF;
  margin: 0 10px 0 0;
  border: 1px solid #851f8282;
  border-radius: 7px;

  &:hover {
    cursor: pointer;
    border: 1px solid #851f82;
    box-shadow: ${shadow.default};
    transition: all 0.1s ease-in-out;
    transform: scale(1.03, 1.03);
  }
`

export const PrimaryActionButton = styled(Button)`
  background: ${props => props.background || colors.brandSecondary};
  color: white;
  width: auto;
  margin-top: 0;
  margin-left: 10px;
`

export const SecondaryActionButton = styled(Button)`
  background: white;
  color: ${props => props.color || colors.gray};
  width: auto;
  margin-top: 0;
  border: 1px solid #909090;
`

export const SIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 3px;
`

export const Table = styled.table`
  width: 95%;
  border: 1px solid #851f8282;
  border-radius: 10px;
  box-shadow: ${shadow.card};
  padding: 10px;
  margin-bottom: 25px;

  td, th { padding: 4px 0 }
  td + td, th + th { padding-left: 4px }
`

export const TR = styled.tr`
`

export const TH = styled.th`
  font-family: ${fonts.textFamily};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: ${props => props.color || colors.grayDarker};
`

export const TD = styled.td`
  font-family: ${fonts.textFamily};
  font-size: 14px;
  line-height: 20px;
  color: ${colors.grayDarker};
`

export const Input = styled(InputField)`
  width: 80%;
  margin: 3px 0 10px 0;
`

export const Select = styled(SelectField)`
  width: 80% !important;
  margin: 3px 0 10px 0;
`

export const DivSelect = styled.div`
  width: 80% !important;
  margin: 3px 0 10px 0;
`

export const RadioGroup = styled.div`
  padding: 8px 0;
`

export const RadioItem = styled.label`
  padding: 0 4px;
`

export const FieldErrorMessage = styled.div`
  display: inline;
  padding: 8px;
  border-radius: 4px;
  margin-top: 4px;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.alert};
`

export const Label = styled.label`
`
