import { colors } from '../styles/theme'

export default function avatarFromName(name) {
  const colorSpace = [`${colors.rainbow0}`, `${colors.rainbow1}`, `${colors.rainbow2}`, `${colors.rainbow3}`]
  const initialsArr = name
    .split(' ')
    .map(str => str.charAt(0).toUpperCase())

  let initials = initialsArr[0]
  if (initialsArr.length > 1) {
    initials += initialsArr[initialsArr.length - 1]
  }

  const charIndex = initials.charCodeAt(0) - 65
  const colorIndex = charIndex % 4

  return {
    initials,
    bgColor: colorSpace[colorIndex]
  }
}
