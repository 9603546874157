import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSectors } from 'store/actions/sector'
import { getAllowedModules } from 'store/actions/role'
import cloe from 'img/cloe.svg'
import * as S from './styles'

export default function Sidebar() {
  const dispatch = useDispatch()
  const [openSectors, setOpenSectors] = useState(false)
  const [openAdmin, setOpenCadastros] = useState(false)
  const [openAdminDashes, setOpenAdminDashes] = useState(false)
  const [openAdminSectors, setOpenAdminSectors] = useState(false)
  const [openAdminUsers, setOpenAdminUsers] = useState(false)

  const { sectors } = useSelector(state => state.sector)
  const { allowedModules } = useSelector(state => state.role)

  useEffect(() => {
    dispatch(getAllSectors())
    dispatch(getAllowedModules())
  }, [dispatch])

  const checkAllowedAnyAdminModule = () => allowedModules.filter(
    allowedModule =>
      allowedModule.manage === true
      && ['sector', 'dashboard', 'users'].includes(allowedModule.name)
  ).length > 0

  const checkAllowedModule = (module) => {
    const matchModule = allowedModules.find(allowedModule => allowedModule.name === module)
    if (matchModule) {
      return matchModule.manage ?? false
    }
    return false
  }

  return (
    <S.SidebarContainer>
      <S.SidebarNav>

        <S.CloeLogoContainer>
          <S.CloeLogo src={cloe} />
        </S.CloeLogoContainer>

        <S.SidebarContent>
          {/* List container */}
          <S.SidebarMenu>
            {/* List item */}
            <S.SidebarMenuItemLink to="/">
              <S.SidebarMenuItem>
                <S.Icon name="home" />
                <S.SidebarMenuLabel>Home</S.SidebarMenuLabel>
              </S.SidebarMenuItem>
            </S.SidebarMenuItemLink>

            <S.ButtonSidebar type="button" className="button" onClick={() => setOpenSectors(!openSectors)}>
              <S.Icon name="trending_up" />
              <S.SidebarMenuLabel>Setores</S.SidebarMenuLabel>
              <S.ArrowIcon name="arrow-drop-down" className={!openSectors ? 'down' : ''} />
            </S.ButtonSidebar>

            {/* Sublist */}
            <S.SidebarMenu>
              {openSectors && (
                <>
                  <S.SidebarMenu>
                    {sectors?.map(sector => (
                      <S.SidebarMenuItemLink key={sector?.id} to={`/${sector?.name}/${sector?.id}/dashboards`}>
                        <S.SidebarSubMenuItem>
                          <S.IconSectors name="" />
                          {' '}
                          {sector?.name}
                        </S.SidebarSubMenuItem>
                      </S.SidebarMenuItemLink>
                    ))}
                  </S.SidebarMenu>
                </>
              )}
            </S.SidebarMenu>

            {checkAllowedAnyAdminModule()
            && (
              <>
                <S.ButtonSidebar type="button" className="button" onClick={() => setOpenCadastros(!openAdmin)}>
                  <S.Icon name="assignment" />
                  <S.SidebarMenuLabel>Admin</S.SidebarMenuLabel>
                  <S.ArrowIcon name="arrow-drop-down" className={!openAdmin ? 'down' : ''} />
                </S.ButtonSidebar>

                <S.SidebarMenu>
                  {openAdmin && (
                    <>
                      {checkAllowedModule('dashboard') && (
                        <S.SidebarMenu>
                          <S.ButtonSidebarSubMenu type="button" className="button" onClick={() => setOpenAdminDashes(!openAdminDashes)}>
                            <S.Icon name="" />
                            <S.SidebarMenuLabel>Dashboards</S.SidebarMenuLabel>
                            <S.ArrowIcon name="arrow-drop-down" className={!openAdminDashes ? 'down' : ''} />
                          </S.ButtonSidebarSubMenu>
                          {openAdminDashes && (
                            <>
                              <S.SidebarMenuItemLink to="/addDashboard">
                                <S.SidebarSubMenuItem>
                                  <S.Icon name="" />
                                  <S.SidebarMenuLabel>Cadastrar</S.SidebarMenuLabel>
                                </S.SidebarSubMenuItem>
                              </S.SidebarMenuItemLink>

                              <S.SidebarMenuItemLink to="/editDashboard">
                                <S.SidebarSubMenuItem>
                                  <S.Icon name="" />
                                  <S.SidebarMenuLabel>Editar</S.SidebarMenuLabel>
                                </S.SidebarSubMenuItem>
                              </S.SidebarMenuItemLink>
                            </>
                          )}
                        </S.SidebarMenu>
                      )}

                      {checkAllowedModule('sector') && (
                        <S.SidebarMenu>
                          <S.ButtonSidebarSubMenu type="button" className="button" onClick={() => setOpenAdminSectors(!openAdminSectors)}>
                            <S.Icon name="" />
                            <S.SidebarMenuLabel>Setores</S.SidebarMenuLabel>
                            <S.ArrowIcon name="arrow-drop-down" className={!openAdminSectors ? 'down' : ''} />
                          </S.ButtonSidebarSubMenu>
                          {openAdminSectors && (
                            <>
                              <S.SidebarMenuItemLink to="/addSector">
                                <S.SidebarSubMenuItem>
                                  <S.Icon name="" />
                                  <S.SidebarMenuLabel>Cadastrar</S.SidebarMenuLabel>
                                </S.SidebarSubMenuItem>
                              </S.SidebarMenuItemLink>

                              <S.SidebarMenuItemLink to="/editSector">
                                <S.SidebarSubMenuItem>
                                  <S.Icon name="" />
                                  <S.SidebarMenuLabel>Editar</S.SidebarMenuLabel>
                                </S.SidebarSubMenuItem>
                              </S.SidebarMenuItemLink>
                            </>
                          )}
                        </S.SidebarMenu>
                      )}
                      {checkAllowedModule('users') && (
                        <S.SidebarMenu>
                          <S.ButtonSidebarSubMenu type="button" className="button" onClick={() => setOpenAdminUsers(!openAdminUsers)}>
                            <S.Icon name="" />
                            <S.SidebarMenuLabel>Usuários</S.SidebarMenuLabel>
                            <S.ArrowIcon name="arrow-drop-down" className={!openAdminUsers ? 'down' : ''} />
                          </S.ButtonSidebarSubMenu>
                          {openAdminUsers && (
                            <>
                              <S.SidebarMenuItemLink to="/editUser">
                                <S.SidebarSubMenuItem>
                                  <S.Icon name="" />
                                  <S.SidebarMenuLabel>Editar</S.SidebarMenuLabel>
                                </S.SidebarSubMenuItem>
                              </S.SidebarMenuItemLink>
                            </>
                          )}
                        </S.SidebarMenu>
                      )}
                    </>
                  )}
                </S.SidebarMenu>
              </>
            )}
          </S.SidebarMenu>
        </S.SidebarContent>
      </S.SidebarNav>
    </S.SidebarContainer>
  )
}
