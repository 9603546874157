// Project imports
import http from 'util/http'

// Action Types
const ADD_SECTOR_REQUEST = 'sector/ADD_SECTOR_REQUEST'
const ADD_SECTOR_SUCCESS = 'sector/ADD_SECTOR_SUCCESS'
const ADD_SECTOR_FAILURE = 'sector/ADD_SECTOR_FAILURE'

const GET_ALL_SECTORS_REQUEST = 'sector/GET_ALL_SECTORS_REQUEST'
const GET_ALL_SECTORS_SUCCESS = 'sector/GET_ALL_SECTORS_SUCCESS'
const GET_ALL_SECTORS_FAILURE = 'sector/GET_ALL_SECTORS_FAILURE'

const UPDATE_SECTOR_REQUEST = 'sector/UPDATE_SECTOR_REQUEST'
const UPDATE_SECTOR_SUCCESS = 'sector/UPDATE_SECTOR_SUCCESS'
const UPDATE_SECTOR_FAILURE = 'sector/UPDATE_SECTORR_FAILURE'

const DELETE_SECTOR_REQUEST = 'sector/DELETE_SECTOR_REQUEST'
const DELETE_SECTOR_SUCCESS = 'sector/DELETE_SECTOR_SUCCESS'
const DELETE_SECTOR_FAILURE = 'sector/DELETE_SECTOR_FAILURE'

export const actions = {
  ADD_SECTOR_REQUEST,
  ADD_SECTOR_SUCCESS,
  ADD_SECTOR_FAILURE,

  GET_ALL_SECTORS_REQUEST,
  GET_ALL_SECTORS_SUCCESS,
  GET_ALL_SECTORS_FAILURE,

  UPDATE_SECTOR_REQUEST,
  UPDATE_SECTOR_SUCCESS,
  UPDATE_SECTOR_FAILURE,

  DELETE_SECTOR_REQUEST,
  DELETE_SECTOR_SUCCESS,
  DELETE_SECTOR_FAILURE
}

/* ACTION CREATORS */

// ADD DASHBOARD
function addSectorRequest() {
  return { type: ADD_SECTOR_REQUEST }
}

export function addSectorSuccess(response) {
  return {
    type: ADD_SECTOR_SUCCESS,
    response
  }
}

function addSectorFailure(error) {
  return {
    type: ADD_SECTOR_FAILURE,
    error
  }
}

// GET ALL SECTORS
function getAllSectorsRequest() {
  return { type: GET_ALL_SECTORS_REQUEST }
}

export function getAllSectorsSuccess(response) {
  return {
    type: GET_ALL_SECTORS_SUCCESS,
    response
  }
}

function getAllSectorsFailure(error) {
  return {
    type: GET_ALL_SECTORS_FAILURE,
    error
  }
}

// UPDATE
function updateSectorRequest() {
  return { type: UPDATE_SECTOR_REQUEST }
}

export function updateSectorSuccess(response) {
  return {
    type: UPDATE_SECTOR_SUCCESS,
    response
  }
}

function updateSectorFailure(error) {
  return {
    type: UPDATE_SECTOR_FAILURE,
    error
  }
}

// DELETE
function deleteSectorRequest() {
  return { type: DELETE_SECTOR_REQUEST }
}

export function deleteSectorSuccess(response) {
  return {
    type: DELETE_SECTOR_SUCCESS,
    response
  }
}

function deleteSectorFailure(error) {
  return {
    type: DELETE_SECTOR_FAILURE,
    error
  }
}

/* THUNK ACTION CREATORS */
// ADD ACTION
export function addSector(values) {
  return async dispatch => {
    dispatch(addSectorRequest())
    try {
      const response = await http({
        method: 'POST',
        url: '/sector/new',
        data: {
          name: values.sectorName
        }
      })
      dispatch(addSectorSuccess(response))
      return response.status
    } catch (error) {
      dispatch(addSectorFailure(error))
      return null
    }
  }
}

// GET ALL SECTORS ACTION
export function getAllSectors() {
  return async dispatch => {
    dispatch(getAllSectorsRequest())
    try {
      const response = await http({
        method: 'GET',
        url: '/sectors/all'
      })
      const { data } = response
      dispatch(getAllSectorsSuccess(data))
      return data
    } catch (error) {
      dispatch(getAllSectorsFailure(error))
      return null
    }
  }
}

// UPDATE ACTION
export function updateSector(values) {
  return async dispatch => {
    dispatch(updateSectorRequest())
    try {
      const response = await http({
        method: 'PUT',
        url: `/sector/${values.id}`,
        data: {
          name: values.name
        }
      })
      dispatch(updateSectorSuccess(response))
      return response.status
    } catch (error) {
      dispatch(updateSectorFailure(error))
      return null
    }
  }
}

// DELETE ACTION
export function deleteSector(id) {
  return async dispatch => {
    dispatch(deleteSectorRequest())
    try {
      const response = await http({
        method: 'DELETE',
        url: `/sector/${id}`
      })
      dispatch(deleteSectorSuccess(response))
      return response.status
    } catch (error) {
      dispatch(deleteSectorFailure(error))
      return null
    }
  }
}
