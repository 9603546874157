// Project imports
import { actions } from '../actions/role'

const initialState = {
  roles: [],
  error: '',
  allowedModules: []
}

function reducer(state = initialState, action) {
  switch (action.type) {
    // GET_ALL_ROLES REQUEST
    case actions.GET_ALL_ROLES_REQUEST:
      return {
        ...state,
        initialState
      }

    // GET_ALL_ROLES SUCCESS
    case actions.GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.response
      }

    // GET_ALL_ROLES FAILURE
    case actions.GET_ALL_ROLES_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    case actions.GET_ALLOWED_MODULES_REQUEST:
      return {
        ...state,
        allowedModules: []
      }

    case actions.GET_ALLOWED_MODULES_SUCCESS:
      return {
        ...state,
        allowedModules: action.data
      }

    case actions.GET_ALLOWED_MODULES_FAILURE:
      return {
        ...state,
        error: action.error
      }

    default:
      return state
  }
}

export default reducer
