// NPM imports
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'

// project imports
import 'App.css'
import Login from '../modules/pages/Login'
import Home from '../views/Home'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import ListDashboards from '../modules/pages/ListDashboards'
import Dashboard from '../modules/pages/Dashboard'
import AddDashboard from '../modules/pages/AddDashboard'
import EditDashboard from '../modules/pages/EditDashboard/index'
import AddSector from '../modules/pages/AddSector'
import EditSector from '../modules/pages/EditSector'
import EditUser from '../modules/pages/EditUser'

function Routes() {
  const { route } = useAuthenticator(context => [context.route])
  if (route === 'signOut') {
    return <></>
  }

  if (route !== 'authenticated') {
    return <Login />
  }

  return (
    <Switch>
      <Route>
        <div className="container">
          <Header />
          <Sidebar className="sidebar" />

          <main className="content">
            <Route path="/" exact isPrivate component={Home} />
            <Route path="/:sectorName/:sectorId/dashboards" exact isPrivate component={ListDashboards} />
            <Route path="/dashboard/:dashboardId" exact isPrivate component={Dashboard} />
            <Route path="/addDashboard" exact isPrivate component={AddDashboard} />
            <Route path="/editDashboard" exact isPrivate component={EditDashboard} />
            <Route path="/addSector" exact isPrivate component={AddSector} />
            <Route path="/editSector" exact isPrivate component={EditSector} />
            <Route path="/editUser" exact isPrivate component={EditUser} />
          </main>
        </div>
      </Route>
    </Switch>
  )
}

export default Routes
