import styled from 'styled-components'

export const DashboardContent = styled.div`
  /* padding: 10px 5px; */
  width: 100%;
  height: 100%;

  iframe {
    border: 0;
  }
`
