import React, { useEffect, useState } from 'react'
import Text from 'camino-web/components/Text'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { getSectorPublicDashboards, getSectorPrivateDashboards } from '../../../store/actions/dashboard'
import * as S from './styles'
import './tabs.css'

const ListDashboards = (match) => {
  const dispatch = useDispatch()
  const sectorId = match.match?.params?.sectorId
  const sectorName = match.match?.params?.sectorName

  const publicDashboards = useSelector(state => state.dashboard.sector_public_dashboards)
  const privateDashboards = useSelector(state => state.dashboard.sector_private_dashboards)
  const [filteredPublicDashboards, setFilteredPublicDashboards] = useState()
  const [filteredPrivateDashboards, setFilteredPrivateDashboards] = useState()

  useEffect(() => {
    dispatch(getSectorPublicDashboards(sectorId))
  }, [sectorId, dispatch])

  useEffect(() => {
    dispatch(getSectorPrivateDashboards(sectorId))
  }, [sectorId, dispatch])

  function filterPublicDashboardsList(event) {
    const name = event.target.value
    if (!name) {
      setFilteredPublicDashboards(publicDashboards)
    } else {
      const filteredDashes = publicDashboards.filter(dashboard => dashboard.name.toLowerCase().includes(name.toLowerCase()))
      setFilteredPublicDashboards(filteredDashes)
    }
  }

  function filterPrivateDashboardsList(event) {
    const name = event.target.value
    if (!name) {
      setFilteredPrivateDashboards(privateDashboards)
    } else {
      const filteredDashes = privateDashboards.filter(dashboard => dashboard.name.toLowerCase().includes(name.toLowerCase()))
      setFilteredPrivateDashboards(filteredDashes)
    }
  }

  return (
    <S.Container>
      <Text type="H2">
        Dashboards de
        <b>
          {' '}
          {sectorName}
        </b>
      </Text>

      <Tabs>
        <TabList>
          <Tab>Público</Tab>
          <Tab>Privado</Tab>
        </TabList>

        <TabPanel>
          <Formik>
            <S.Input
              name="search"
              placeholder="Pesquisar dashboards"
              maxLength={72}
              onChange={filterPublicDashboardsList}
            />
          </Formik>

          <S.Items>
            {(filteredPublicDashboards ?? publicDashboards).map(dashboard => (
              <S.Main to={`/dashboard/${dashboard.id}`} key={dashboard.id}>
                <S.Item textDashboard={dashboard.name} />
              </S.Main>
            ))}
          </S.Items>
        </TabPanel>
        <TabPanel>
          <Formik>
            <S.Input
              name="search"
              placeholder="Pesquisar dashboards"
              maxLength={72}
              onChange={filterPrivateDashboardsList}
            />
          </Formik>

          <S.Items>
            {(filteredPrivateDashboards ?? privateDashboards).map(dashboard => (
              <S.Main to={`/dashboard/${dashboard.id}`} key={dashboard.id}>
                <S.Item textDashboard={dashboard.name} />
              </S.Main>
            ))}
          </S.Items>
        </TabPanel>
      </Tabs>

    </S.Container>
  )
}

export default ListDashboards
