import styled, { css } from 'styled-components'
import { mediaQuery } from 'styles/responsive'
import { colors, fonts, shadow } from 'styles/theme'
import Text from '../Text'
import Icon from '../Icon'

export const animDuration = 300

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 492px;
  padding: 8px;
  pointer-events: ${(props) => (props.hasToast ? 'auto' : 'none')};
  z-index: 1000;

  @media ${mediaQuery.maxMd} {
    width: 344px;
    padding: 16px;
  }

  @media ${mediaQuery.maxSm} {
    width: 100%;
  }
`

export const Box = styled.div`
  border-radius: 8px;
  box-shadow: ${shadow.default};
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;
  min-height: 40px;

  padding: 16px 56px 16px 16px;
  opacity: 1;
  transform: translateX(0);
  transition: ${animDuration}ms;

  &.toast-anim-enter {
    opacity: 0;
    transform: translateX(-40px);
  }
  &.toast-anim-enter.toast-anim-enter-active {
    opacity: 1;
    transform: translateX(0);
  }

  &.toast-anim-exit {
    opacity: 1;
    transform: translateX(0);
  }
  &.toast-anim-exit.toast-anim-exit-active {
    opacity: 0;
    transform: translateX(-40px);
  }

  & + & {
    margin-top: 8px;
  }
`

export const Action = styled.button`
  margin-top: 7px;
  margin-left: 30px;
  cursor: pointer;
  padding: 0;
  border: 0 none;
  font-family: ${fonts.textFamily};
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  overflow-wrap: break-word;
  color: ${colors.brandSecondary};
  background: transparent;
`

export const Loader = styled.img`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  border: 0 none;
  margin: 0;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  border: 0 none;
  margin: 0;
  color: ${colors.white};
  background: transparent;
  cursor: pointer;

  &:hover {
    color: ${colors.white};
  }
`

export const applyIconTheme = (type) => {
  switch (type) {
    case 'success':
      return css`
        color: ${colors.success};
      `
    case 'alert':
      return css`
        color: ${colors.alert};
      `
    case 'loading':
      return css`
        color: ${colors.grayDarker};
      `
    default:
      return css`
        color: ${colors.success};
      `
  }
}

export const applyTypeTheme = (type) => {
  switch (type) {
    case 'success':
      return css`
        background-color: ${colors.success};
      `
    case 'alert':
      return css`
        background-color: ${colors.alert};
      `
    case 'loading':
      return css`
        background-color: ${colors.grayDarker};
      `
    default:
      return css`
        background-color: ${colors.success};
      `
  }
}

export const Type = styled.div`
  position: absolute;
  width: 4px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 8px 0px 0px 8px;
  ${(props) => applyTypeTheme(props.type)}
`

export const Wrapper = styled.div`
  display: flex;
`

export const SIcon = styled(Icon)`
  ${(props) => applyIconTheme(props.type)}
`

export const SText = styled(Text)`
  padding-top: 1px;
  padding-left: 10px;
`
