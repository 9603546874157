// NPM imports

import axios from 'axios'
import { Auth } from 'aws-amplify'

// Project imports

const baseURL = process.env.REACT_APP_API_BASE_URL

const caminoAxios = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
})

caminoAxios.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession()
  const { headers } = config
  headers.Authorization = `Bearer ${session.getAccessToken().getJwtToken()}`
  return config
})

export default caminoAxios
