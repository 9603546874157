// NPM imports

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Project imports

import Icon from 'camino-web/components/Icon'
import { colors, fonts } from 'styles/theme'

// Styled components

const Container = styled.div`
  position: relative;
`

const Label = styled.label`
  position: absolute;
  top: -10px;
  left: 12px;
  display: block;
  padding: 0 4px;
  font-family: ${fonts.textFamily};
  font-size: 10px;
  line-height: 20px;
  color: ${colors.gray};
  background: white;
  ${props => (props.error && `color: ${colors.alert}`)};
  ${props => (props.focused && `color: ${colors.brandPrimary}`)};

`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0;
  border: 1px solid ${colors.gray};
  border-radius: 8px;
  ${props => props.error && `border: 1px solid ${colors.alert}`}
  ${props => props.focused && `border: 1px solid ${colors.brandPrimary}`}
`
const Input = styled.input`
  flex: 1;
  display: block;
  padding: 8px 16px;
  border: 0 none;
  font-family: ${fonts.textFamily};
  font-size: 12px;
  line-height: 24px;
  background: transparent;
  outline: none;
`

const IconButton = styled.button`
  display: block;
  border: 0 none;
  padding-right: 8px;
  background: transparent;
`

// Component

export function InputField({
  className,
  label,
  icon,
  onIconClick,
  onFocus,
  onBlur,
  hasError,
  ...props
}) {
  const [focused, setFocused] = useState(false)
  function preventFocus(e) {
    // prevent focus on click
    e.preventDefault()
  }

  return (
    <Container className={className}>
      <Label focused={focused} error={hasError}>{label}</Label>
      <InputContainer focused={focused} error={hasError}>
        <Input
          /* eslint-disable react/jsx-props-no-spreading */
          {...props}
          onFocus={(e) => {
            if (onFocus) { onFocus(e) }
            setFocused(true)
          }}
          onBlur={(e) => {
            if (onBlur) { onBlur(e) }
            setFocused(false)
          }}
        />
        {icon && (
          <IconButton
            type="button"
            tabIndex="-1"
            onClick={onIconClick}
            onMouseDown={preventFocus}
          >
            <Icon
              name={icon}
              size="24px"
              color={colors.grayLight}
            />
          </IconButton>
        )}
      </InputContainer>
    </Container>
  )
}

InputField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  onIconClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  hasError: PropTypes.bool
}

InputField.defaultProps = {
  className: '',
  label: '',
  icon: null,
  onIconClick: null,
  onFocus: () => {},
  onBlur: () => {},
  hasError: false
}

export default InputField
