import styled, { keyframes } from 'styled-components'
import { colors, fonts } from 'styles/theme'
import Icon from '../Icon'

export const SIcon = styled(Icon)``

export const Text = styled.span`
  font-family: ${fonts.titleFamily};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`

export const Container = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${colors.brandSecondary};
  border: 1px solid ${colors.brandSecondary};
  border-radius: 24px;
  color: white;
  cursor: pointer;
  padding: 8px 16px;
  outline: none;

  ${props => props.secondary
    && `
      background: white;
      color: ${colors.brandSecondary};
      border: 1px solid ${colors.brandSecondary};
    `}

  ${props => props.outline
    && `
      background: transparent;
      color: white;
      border: 1px solid white;
    `}

  ${props => props.disabled
    && `
      background: ${colors.grayLighter};
      color: ${colors.gray};
      border: none;

      ${SIcon} {
        color: ${colors.gray};
      }
    `}

  ${props => props.isAlert
    && `
      background: transparent;
      color: ${colors.alert};
      border: 1px solid ${colors.alert};
    `}

  ${props => props.isGhost
    && `
      background: transparent;
      color: ${colors.gray};
      text-decoration: underline;
      border: 0 none;

      ${SIcon} {
        text-decoration: none;
      }
    `}

  ${({ size }) => size === 'sm'
    && `
      padding: 8px;

      ${Text} {
        line-height: 16px;
      }
    `}

  ${({ size }) => size === 'lg'
    && `
      padding: 16px 24px;
      border-radius: 32px;

      ${Text} {
        font-size: 16px;
      }
    `}
  
  ${({ isLoading }) => isLoading
    && `
      ${Text}, ${SIcon} { visibility: hidden }
    `}

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled(Icon).attrs({
  name: 'spinner',
  size: '24px'
})`
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -12px 0 0 -12px;
  color: ${colors.gray};
  animation: ${rotate} 1s ease-in infinite;
`
