import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAuthenticator } from '@aws-amplify/ui-react'

function InitMachine({ children, ...data }) {
  const { _send, route } = useAuthenticator()
  const hasInitialized = useRef(false)

  useEffect(() => {
    if (!hasInitialized.current && route === 'idle') {
      _send({
        type: 'INIT',
        data
      })
      hasInitialized.current = true
    }
  }, [_send, route, data])
  return <>{children}</>
}

InitMachine.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired
}

export default InitMachine
