import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Icons from '../../camino-web/components/Icon'

export const SidebarContainer = styled.div`
  position: fixed;
  padding: 40px 0;
  background-color: #851F82;
  z-index: 1;
  
  // impede a seleção de texto no componente
  -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
`

export const SidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 240px;
  width: 100%;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  border-radius: 0px 10px 10px 0px;
`

export const CloeLogoContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  /* width: 130px; */
  height: 130px;
  padding: 16px;
  margin: 0 auto;
  justify-content: center;
  border-radius: 50%;
`

export const CloeLogo = styled.img`
  width: 100px;
  height: auto;
`

export const SidebarContent = styled.div`
  width: 240px;
  margin-top: 40px;
  padding: 0 0 50px 0;
  border-box: box-sizing;
  list-style: none;
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;

  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #e564e1;
    border: 0px none #ffffff;
    border-radius: 0px;
  }
`

export const SidebarMenu = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

export const SidebarMenuItemLink = styled(Link)`
  color: #fff;
  line-height: 1.5;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
`

export const SidebarMenuItem = styled.li` 
  padding: 15px 0;
  box-sizing: border-box;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;
  }
`

export const SidebarSubMenuItem = styled.li`
  width: 100%;
  display: inline-block;
  position: relative;
  background-color: #6c1069;
  line-height: 3.5;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;
  }
  padding-left: 20px;
`
export const SidebarMenuLabel = styled.span``

export const Icon = styled(Icons)`
  vertical-align: -20%;
  padding: 0 10px 0 20px;
  display: inline-block;
`

export const ArrowIcon = styled(Icons)`
  display: inline-block;
  &.down {
    transform: rotate(180deg);
    transition: all 0.4s ease;
  }
`

export const IconSectors = styled(Icon)`
  filter: invert(1);
`

export const IconSubMenu = styled(Icon)`
  padding-left: 25px;
`

export const ButtonSidebar = styled.button` 
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #851F82;
  border: 0;
  padding: 0;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  cursor: pointer;
  outline: 0;
  line-height: 3.5;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;
  }
`

export const ButtonSidebarSubMenu = styled(ButtonSidebar)`
  background-color: #761a73;
`

export const SidebarSubMenuItemLink = styled(SidebarMenuItemLink)`
  background-color: #761a73;
`
