// NPM imports
import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Authenticator } from '@aws-amplify/ui-react'
import Toast from 'camino-web/components/Toast'
import InitMachine from './components/InitMachine'

// project imports
import 'App.css'
import Routes from './routes'

const customHistory = createBrowserHistory()
customHistory.listen(() => window?.tracker?.pageView())

const App = () => (
  <Authenticator.Provider>
    <InitMachine>
      <Router history={customHistory}>
        <Routes />
        <Toast />
      </Router>
    </InitMachine>
  </Authenticator.Provider>
)
export default App
