import styled from 'styled-components'
import { colors, fonts, shadow } from 'styles/theme'

export const ItemContainer = styled.div`
  display: inline-block;
`

export const ItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Item = styled.div`
  width: 130px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.textFamily};
  box-shadow: ${shadow.card};
  border: 2px solid ${ colors.brandPrimary };
  border-radius: 6px;
`

export const IconDashboard = styled.img`
  width: 60px;
  height: auto;
`

export const NameDashboardContent = styled.div`
  margin: 10px;
`

export const NameDashboard = styled.p`
  font-family: ${fonts.textFamily};
  text-align: center;
`
