// NPM imports
import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

// Project imports
import { colors } from 'styles/theme'
import Icon from '../Icon'
import Text from '../Text'
import patternLoading from '../../../img/loading-animation.svg'
import { useToast } from './hook'
import {
  Container,
  animDuration,
  Box,
  Type,
  Wrapper,
  SIcon,
  SText,
  Loader,
  CloseButton,
  Action
} from './styles'

// Component

const getIconType = (type) => {
  switch (type) {
    case 'success':
      return 'check-circle'

    case 'alert':
      return 'error'

    case 'loading':
      return 'upload'

    default:
      return 'check-circle'
  }
}

const Toast = () => {
  const { messages, clearToast } = useToast()
  const [toastMessages, setToastMessages] = useState([])
  const timeoutListRef = useRef({})

  useEffect(() => {
    // update toast messages
    setToastMessages(messages)

    // then review timeoutListRef
    const messagesIds = messages.map(({ id }) => id)
    const messagesWithTimeoutIds = messages
      .filter(({ preventAutoDismiss }) => !preventAutoDismiss)
      .map(({ id }) => id)
    const timeoutListIds = Object.entries(timeoutListRef.current).map(
      ([id]) => id
    )
    const idsToRemoveFromTimeoutList = timeoutListIds.filter(
      (id) => !messagesIds.includes(id)
    )
    const idsToAddToTimeoutList = messagesWithTimeoutIds.filter(
      (id) => !timeoutListIds.includes(id)
    )

    // cancel and remove timeouts for messages
    // that doesn't exist anymore
    idsToRemoveFromTimeoutList.forEach((idToRemove) => {
      const timeoutId = timeoutListRef.current[idToRemove]
      clearTimeout(timeoutId)
      delete timeoutListRef.current[idToRemove]
    })

    // add timeouts for new messages
    idsToAddToTimeoutList.forEach((idToAdd) => {
      if (
        messages.find((message) => message?.id === idToAdd)?.type !== 'loading'
      ) {
        timeoutListRef.current[idToAdd] = setTimeout(() => {
          clearToast(idToAdd)
        }, 6000)
      }
    })
  }, [clearToast, messages])

  return (
    <Container hasToast={toastMessages[0]}>
      <TransitionGroup component={null} appear>
        {toastMessages.map(
          ({ id, type, text, currentFile, totalFiles, action }) => (
            <CSSTransition
              key={id}
              classNames="toast-anim"
              timeout={animDuration}
              in
            >
              <Box>
                <Type type={type} />
                <Wrapper>
                  <SIcon type={type} size="20px" name={getIconType(type)} />
                  {type === 'loading' ? (
                    <SText type="P2">
                      {text}
                      {currentFile + 1}
                      /
                      {totalFiles}
                    </SText>
                  ) : (
                    <SText type="P2">{text}</SText>
                  )}

                  {type === 'loading' ? (
                    <Loader width="24px" height="24px" src={patternLoading} />
                  ) : (
                    <CloseButton
                      type="button"
                      onClick={() => {
                        clearToast(id)
                      }}
                    >
                      <Icon
                        name="close"
                        color={colors.grayLighter}
                        size="24px"
                      />
                    </CloseButton>
                  )}
                </Wrapper>
                {action && (
                  <Action onClick={action.onClick}>
                    <Text type="P2" isBold color={colors.brandSecondary}>
                      {action.children}
                    </Text>
                  </Action>
                )}
              </Box>
            </CSSTransition>
          )
        )}
      </TransitionGroup>
    </Container>
  )
}

export default Toast

// re export hook from Toast
export * from './hook'
