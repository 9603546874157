import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ItemDashboard from 'components/ItemDashboard'
import { Field } from 'formik'

export const Container = styled.div`
  padding: 20px;
`

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
`

export const Main = styled(Link)`
  display: flex;
  flex-basis: 12%;
  flex-shrink: 1;
  justify-content: center;
  
  width: 160px;
  height: 125px;
  padding: 30px;
  margin: 10px;
  cursor: pointer;
  color: inherit;
  text-decoration:none;
  border-radius: 24px;
  
  &:hover {
    font-weight: bold;
  }
`

export const Item = styled(ItemDashboard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

export const Input = styled(Field)`
  width: 100%;
  max-width: 300px;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 8px;
  margin-top: 10px;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px, rgb(227, 230, 232) 0px 0px 0px
        3px;
    border: 1.6px solid #851F82;
    outline: none;
`
