/* eslint-disable react/jsx-props-no-spreading */
// NPM imports

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Container } from './styles'

// Component
function Card({ className, children, type, ...props }) {
  function getTagOrComponent() {
    switch (type) {
      case 'button':
      case 'submit':
        return 'button'
      case 'link':
        return Link
      default:
        return 'div'
    }
  }

  return (
    <Container
      {...props}
      className={className}
      as={getTagOrComponent()}
    >
      {children}
    </Container>
  )
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'link'])
}

Card.defaultProps = {
  className: '',
  type: null
}

export default Card
