import React, { useEffect, useState } from 'react'
import * as pbi from 'powerbi-client'
import { useToast } from 'camino-web/components/Toast'
import { DashboardContent } from './styles'
import { getEmbedToken } from '../../../store/actions/dashboard'

const Dashboard = (match) => {
  const dashboardId = match.match?.params?.dashboardId
  const [embedData, setEmbedData] = useState(null)
  const { addToast } = useToast()

  useEffect(() => {
    getEmbedToken(dashboardId)
      .then(response => setEmbedData(response))
      .catch(error => {
        console.error(error)
        addToast('Falha ao gerar o PowerBI EmbedToken', { type: 'alert' })
      })
  }, [addToast, dashboardId])

  useEffect(() => {
    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory)

    if (powerbi && embedData) {
      const reportContainer = document.getElementById('dashboardContainer')

      const embedConfiguration = {
        ...embedData,
        tokenType: pbi.models.TokenType.Embed,
        type: 'report'
      }

      const newSettings = {
        panes: {
          filters: {
            visible: false
          }
        }
      }

      // If you have embedded a report within an element and want to reset the element back to its initial state,
      // call: powerbi.reset(element); This method removes the embed from the service and removes the iframe
      // (required to prevent the service from holding on to reference that doesn't exist in the DOM).
      // You typically need to call reset before the containing element is removed from the DOM by the parent
      powerbi.reset(reportContainer)
      powerbi.embed(reportContainer, embedConfiguration).updateSettings(newSettings)
    }
  }, [embedData, dashboardId])

  return (
    <DashboardContent id="dashboardContainer" />
  )
}

export default Dashboard
