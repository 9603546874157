// code extracted from https://codesandbox.io/s/6ll36y9qjw
// (view this discussion: https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8)

// NPM imports

import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { transparentize } from 'polished'
import PropTypes from 'prop-types'
// Project imports

import { colors, shadow, fonts } from 'styles/theme'

// Styles

const StyledSelect = styled(Select)`
  width: 100%;
  min-width: 100px;
  .react-select__control {
    /*border: 2px solid ${(props) =>
  (props.isInvalid ? colors.alert : colors.grayLighter)};*/
    border: none;

    font-size: 14px;
    line-height: 16px;
    background: ${colors.white};

    ${({ type }) =>
    type === 'sm'
      && `
    min-height: 40px;
    border-radius: 8px;
    border: 1px solid ${colors.grayLight};
    `};

    ${({ type }) =>
        type === 'md'
      && `
    min-height: 56px;
    border-radius: 16px;
    box-shadow: ${shadow.default};
    `};

    ${({ type }) =>
        type === 'lg'
      && `
    min-height: 56px;
    border-radius: 16px 0px 0px 16px;
    border: 1px solid ${colors.grayLighter};
    `};

    &:hover {
      border-color: ${colors.grayLighter};
      background: ${colors.grayLighter};
    }

    .react-select__indicator-separator {
      display: none;
    }

    &.react-select__control--is-focused {
      border-color: ${colors.brandPrimary};
      outline: 0 none;
      background: white;
      box-shadow: ${shadow.default};
    }

    .react-select__single-value {
      font-family: ${fonts.textFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }

    .react-select__multi-value {
      border-radius: 12px;
      align-items: center;

      .react-select__multi-value__remove {
        background: ${colors.gray};
        border-radius: 50%;
        margin-right: 4px;
        height: 16px;
        width: 16px;

        svg {
          color: ${colors.white};
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  .react-select__menu {
    border: 1px solid ${transparentize(0.9, colors.brandPrimary)};
    box-shadow: ${shadow.default};
  }

  .react-select__menu-list {
    margin: 8px 4px;

    .react-select__option {
      font-family: ${fonts.textFamily};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      &.react-select__option--is-focused {
        background: ${transparentize(0.9, colors.brandPrimary)};
        border-radius: 8px;
        color: ${colors.black80};
      }

      &.react-select__option--is-selected {
        border-radius: 8px;
        color: ${colors.black80};
        background: ${transparentize(0.8, colors.brandPrimary)};
      }
    }
  }
`

// Component

export function SelectField({
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  onChange,
  type = 'sm'
}) {
  const handleChange = (option) => {
    if (typeof onChange === 'function') {
      onChange(option)
    }

    form.setFieldValue(
      field.name,
      // eslint-disable-next-line no-nested-ternary
      isMulti ? (option ? option.map((item) => item.value) : []) : option.value
    )
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? Array.isArray(field?.value)
            && field.value.map((value) =>
              options?.find((option) => option.value === value))
        : options.find((option) => option.value === field.value) || null
    }
    return isMulti ? [] : ''
  }

  return (
    <StyledSelect
      classNamePrefix="react-select"
      name={field.name}
      value={getValue()}
      onChange={handleChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      type={type}
    />
  )
}

SelectField.propTypes = {
  field: PropTypes.objectOf(PropTypes.any),
  placeholder: PropTypes.string,
  form: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string
}

SelectField.defaultProps = {
  placeholder: '',
  field: null,
  form: null,
  options: null,
  isMulti: false,
  onChange: () => null,
  type: 'sm'
}

export default SelectField
