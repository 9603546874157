// Project imports
import { actions } from '../actions/sector'

const initialState = {
  id: null,
  name: '',
  sectors: [],
  error: ''
}

function reducer(state = initialState, action) {
  switch (action.type) {
    // ADD SECTOR REQUEST
    case actions.ADD_SECTOR_REQUEST:
      return {
        ...state,
        initialState
      }

    // ADD SECTOR SUCCESS
    case actions.ADD_SECTOR_SUCCESS:
      return {
        ...state,
        name: action.name,
        admin_id: action.admin_id
      }

    // ADD SECTOR FAILURE
    case actions.ADD_SECTOR_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // GET_ALL_SECTORS REQUEST
    case actions.GET_ALL_SECTORS_REQUEST:
      return {
        ...state,
        initialState
      }

    // GET_ALL_SECTORS SUCCESS
    case actions.GET_ALL_SECTORS_SUCCESS:
      return {
        ...state,
        sectors: action.response
      }

    // GET_ALL_SECTORS FAILURE
    case actions.GET_ALL_SECTORS_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // UPDATE SECTOR REQUEST
    case actions.UPDATE_SECTOR_REQUEST:
      return {
        ...state,
        initialState
      }

    // UPDATE SECTOR SUCCESS
    case actions.UPDATE_SECTOR_SUCCESS:
      return {
        ...state,
        id: action.id,
        name: action.name,
        admin_id: action.admin_id
      }

    // UPDATE SECTOR FAILURE
    case actions.UPDATE_SECTOR_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    // DELETE SECTOR REQUEST
    case actions.DELETE_SECTOR_REQUEST:
      return {
        ...state,
        initialState
      }

    // DELETE SECTOR SUCCESS
    case actions.DELETE_SECTOR_SUCCESS:
      return {
        ...state
      }

    // DELETE SECTOR FAILURE
    case actions.DELETE_SECTOR_FAILURE:
      return {
        ...state,
        initialState,
        error: action.error
      }

    default:
      return state
  }
}

export default reducer
