import React, { useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useAuthenticator } from '@aws-amplify/ui-react'

// Project imports
import { colors, shadow } from 'styles/theme'
import { mediaQuery } from 'styles/responsive'
import Icon from 'camino-web/components/Icon'
import Text from 'camino-web/components/Text'
import Button from 'camino-web/components/Buttons'
import ConfirmationModal from './ConfirmationModal'
import ProfilePicture from './ProfilePicture'

// Styled components
const animDuration = 300

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 30;
  top: 50%;
  right: 50%;
  transform: translateX(50%) translateY(-50%);
  width: 100%;
  min-height: 370px;
  border-radius: 16px;
  background: ${colors.white};
  padding: 24px 0 0;
  overflow: scroll;
  padding-bottom: 80px;

  box-shadow: ${shadow.dialogBox};

  @media ${mediaQuery.maxLg} {
    height: 100%;
    justify-content: flex-start;
    border-radius: 0;
  }

  @media ${mediaQuery.minLg} {
    max-width: 312px;
    position: absolute;
    top: 80px;
    right: 40px;
    transform: translateX(0) translateY(0);
    min-height: 230px;
    padding-bottom: 0;
    overflow: unset;
  }

  &.actionsheet-anim-enter {
    opacity: 0;
    transform: translateX(50%) translateY(-40%);

    @media ${mediaQuery.minMd} {
      transform: translateX(0) translateY(40px);
    }
  }
  &.actionsheet-anim-enter-active {
    opacity: 1;
    transform: translateX(50%) translateY(-50%);
    transition: all ${animDuration}ms;

    @media ${mediaQuery.minMd} {
      transform: translateX(0) translateY(0px);
    }
  }

  &.actionsheet-anim-exit {
    opacity: 1;
    transform: translateX(50%) translateY(-50%);

    @media ${mediaQuery.minMd} {
      transform: translateX(0) translateY(0px);
    }
  }

  &.actionsheet-anim-exit-active {
    opacity: 0;
    transform: translateX(50%) translateY(-40%);
    transition: all ${animDuration}ms;

    @media ${mediaQuery.minMd} {
      transform: translateX(0) translateY(40px);
    }
  }
`

const HeaderButton = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  padding: 16px;

  cursor: pointer;

  @media ${mediaQuery.minLg} {
    display: none;
  }
`

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Name = styled(Text).attrs({ isBold: true, type: 'H2' })`
  margin-top: 20px;
  margin-bottom: 0px;
  text-align: center;
  color: ${colors.brandPrimary};
`

const Email = styled(Text).attrs({ type: 'H6' })`
  margin-top: 8px;
  text-align: center;
  color: ${colors.gray};
`

const ButtonLogout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0 0 0;
  border-top: 0.5px solid rgb(192 192 192 / 50%);
  outline: none;
`
const SButton = styled(Button)`
  margin: 15px;
  width: 130px;
  height: 35px;
`

const Backdrop = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;

  @media ${mediaQuery.minMd} {
    display: none;
  }
`

function SettingOptionsModal({ isShowing, onClose }) {
  const { user, signOut } = useAuthenticator(context => [context.user])
  const { name, picture, email } = user.getSignInUserSession().getIdToken().payload
  const ContentRef = useRef([])

  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const renderSettingOptions = () => (
    <Content ref={ContentRef}>
      <HeaderButton onClick={onClose}>
        <Icon name="close" color={colors.gray} />
      </HeaderButton>
      <ProfileInfo>
        <ProfilePicture imgUrl={picture} size={104} fullName={name} />
        <Name>{name}</Name>
        <Email>{email}</Email>
      </ProfileInfo>
      <ButtonLogout>
        <SButton
          text="Logout"
          onClick={() => {
            setIsLoggingOut(true)
          }}
        />
      </ButtonLogout>
    </Content>
  )

  return (
    <CSSTransition
      classNames="actionsheet-anim"
      timeout={animDuration}
      in={isShowing}
      unmountOnExit
      appear
    >
      <>
        {renderSettingOptions()}
        <Backdrop onClick={onClose} />
        <ConfirmationModal
          title="Você está saindo da Plataforma"
          text="Deseja realmente sair?"
          primaryAction={{
            text: 'Sim, quero Sair',
            onClick: signOut
          }}
          secondaryAction={{
            text: 'Cancelar',
            onClick: () => {
              setIsLoggingOut(false)
            }
          }}
          onBackdropClick={() => {
            setIsLoggingOut(false)
          }}
          isShowing={isLoggingOut}
        />
      </>
    </CSSTransition>
  )
}

SettingOptionsModal.propTypes = {
  isShowing: PropTypes.bool,
  onClose: PropTypes.func
}

SettingOptionsModal.defaultProps = {
  isShowing: false,
  onClose: () => { }
}

export default SettingOptionsModal
