import styled from 'styled-components'
import Card from 'camino-web/components/Card'
import { shadow } from 'styles/theme'

export const GIcon = styled.img`
  width: 37px;
  height: 37px;
  font-size: 24px;
`

export const LoginOption = styled(Card)`
  margin-top: 16px;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${shadow.default};
  border-radius: 50px;
  cursor: pointer;
  ${props => props.marginRight && 'margin-right: 16px;'}
`
