import React from 'react'
import PropTypes from 'prop-types'
import iconDashboard from 'img/icon-dashboard.svg'
import * as S from './styles'

function ItemDashboard({ textDashboard = 'nome da dashboard' }) {
  return (
    <S.ItemContainer>
      <S.ItemContent>
        <S.Item>
          <S.IconDashboard src={iconDashboard} />
        </S.Item>
        <S.NameDashboardContent>
          <S.NameDashboard>
            {textDashboard}
          </S.NameDashboard>
        </S.NameDashboardContent>
      </S.ItemContent>
    </S.ItemContainer>
  )
}

ItemDashboard.propTypes = {
  textDashboard: PropTypes.string.isRequired
}

export default ItemDashboard
